import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpContextToken } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { UserService } from './user.service';
import { AuthService } from '../auth/auth.service';
import { switchMap, filter, take, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

export const SKIP_AUTH = new HttpContextToken<boolean>(() => false);

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    private _isRefreshing = false;
    private _refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private _userService: UserService,
        private _jwtHelper: JwtHelperService,
        private _authService: AuthService,
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const skipAuth = request.context.get(SKIP_AUTH);

        const skipAuthHeaderValue = request.headers.get('skip_auth');
        const skipAuthAWS = skipAuthHeaderValue === 'true';

        if (skipAuth || skipAuthAWS) {
          return next.handle(request);
        }

        const token = localStorage.getItem('token');

        return next.handle(this.addToken(request, token)).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === 401 || this._jwtHelper.isTokenExpired(token)) {
                    return this.handleRefresh(request, next);
                }
                return throwError(error);
            }));
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private handleRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!this._isRefreshing) {
            this._isRefreshing = true;
            this._refreshTokenSubject.next(null);

            return this._userService.getAccessToken(this._authService.getIdToken()).pipe(
                switchMap((token) => {
                    const jwtToken = token.access_token;
                    this._authService.setJwtToken(jwtToken);
                    this._isRefreshing = false;
                    this._refreshTokenSubject.next(jwtToken);
                    return next.handle(this.addToken(request, jwtToken));
                }));
        }

        return this._refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap(jwt => {
                return next.handle(this.addToken(request, jwt));
            })
        );
    }
}
