import { Injectable } from '@angular/core';
import { FilterStatic } from '../components/filter/filter.static';
import { WorkerFunction } from '../workers/workers-functions';

type workerFunction<T, R> = (d: { data: T; }) => R;

type adminAssetFallbackParamData = Parameters<typeof WorkerFunction.adminAssets>[0]['data'];
type adminAssetFallbackReturn = ReturnType<typeof WorkerFunction.adminAssets>;

type esriMapFallbackParamData = Parameters<typeof WorkerFunction.esriMap>[0]['data'];
type esriMapFallbackReturn = ReturnType<typeof WorkerFunction.esriMap>;

type formatAssetFallbackParamData = Parameters<typeof WorkerFunction.formatAssets>[0]['data'];
type formatAssetFallbackReturn = ReturnType<typeof WorkerFunction.formatAssets>;

type filterFallbackParamData = Parameters<typeof FilterStatic.workerProcess>[0]['data'];
type filterFallbackReturn = ReturnType<typeof FilterStatic.workerProcess>;

type familyUsersRoleLevelFallbackParamData = Parameters<typeof WorkerFunction.familyUsersRoleLevel>[0]['data'];
type familyUsersRoleLevelFallbackReturn = ReturnType<typeof WorkerFunction.familyUsersRoleLevel>;

type arrayChangesFallbackParamData = Parameters<typeof WorkerFunction.arrayChanges>[0]['data'];
type arrayChangesFallbackReturn = ReturnType<typeof WorkerFunction.arrayChanges>;

@Injectable({
    providedIn: 'root'
})
export class WorkerService {

    /**
     * Flag for the Worker availability
     * It checks both the Worker definition AND the URL constructor as they are both used to create the worker
     */
    private readonly _isWorkerAvailable = !!('Worker' in window && !!window.Worker) && !!('URL' in window && !!URL.constructor);

    public get adminAssets(): Worker {
        return this._isWorkerAvailable ?
            new Worker(new URL('../workers/admin-assets.worker', import.meta.url)) :
            this._createFallbackSimulatedWorker<adminAssetFallbackParamData, adminAssetFallbackReturn>(WorkerFunction.adminAssets);
    }

    public get mapViewWorker(): Worker {
        return this._isWorkerAvailable ?
            new Worker(new URL('../workers/esri-map.worker', import.meta.url)) :
            this._createFallbackSimulatedWorker<esriMapFallbackParamData, esriMapFallbackReturn>(WorkerFunction.esriMap);
    }

    public get formatAssets(): Worker {
        return this._isWorkerAvailable ?
            new Worker(new URL('../workers/format-assets.worker', import.meta.url)) :
            this._createFallbackSimulatedWorker<formatAssetFallbackParamData, formatAssetFallbackReturn>(WorkerFunction.formatAssets);
    }

    public filters: Worker = this._isWorkerAvailable ?
        new Worker(new URL('../components/filter/filter.worker', import.meta.url)) :
        this._createFallbackSimulatedWorker<filterFallbackParamData, filterFallbackReturn>(FilterStatic.workerProcess);

    public get familyUsersRoleLevel(): Worker {
        return this._isWorkerAvailable ?
            new Worker(new URL('../workers/family-users-role-level.worker', import.meta.url)) :
            this._createFallbackSimulatedWorker<familyUsersRoleLevelFallbackParamData, familyUsersRoleLevelFallbackReturn>(WorkerFunction.familyUsersRoleLevel);
    }

    public get arrayChanges(): Worker {
        return this._isWorkerAvailable ?
            new Worker(new URL('../workers/array-changes.worker', import.meta.url)) :
            this._createFallbackSimulatedWorker<arrayChangesFallbackParamData, arrayChangesFallbackReturn>(WorkerFunction.arrayChanges);
    }

    private _createFallbackSimulatedWorker<T, R>(fallback: workerFunction<T, R>) {
        return new SameThreadWorker(fallback) as any;
    }

}

class SameThreadWorker<T, R> extends EventTarget {

    private _computeFoo: workerFunction<T, R>;

    constructor(
        foo: workerFunction<T, R>
    ) {
        super();
        this._computeFoo = foo;
    }

    public postMessage(data: T): void {
        try {
            const results = this._computeFoo({ data });
            const messageEvt = new MessageEvent<R>('message', { data: results });
            this.dispatchEvent(messageEvt);
        } catch (error) {
            const errorEvt = new ErrorEvent('error', { error, message: error?.message });
            this.dispatchEvent(errorEvt);
        }
    }

}
