import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Zone } from 'src/app/models/zone';
import { Store } from '@ngrx/store';
import { selectZoneById$ } from 'src/app/store/selectors/zone.selector';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-pop-in-zone-info',
    templateUrl: './pop-in-zone-info.component.html',
    styleUrls: ['./pop-in-zone-info.component.scss']
})
export class PopInZoneInfoComponent {

    constructor(
        @Inject(Zone) public zone: Zone,
        private store: Store,
        private _cd: ChangeDetectorRef,
    ) {}

    public getParentZone(): Observable<Zone['name']> {
        return this.store.select(selectZoneById$(this.zone.parent)).pipe(
            map(zone => zone?.name),
            tap(() => this._cd.markForCheck()),
        );
    }
}
