<div class="popin">
    <div class="zone-info">
        <div class="zone-level">
            <label>{{ "ZONE_LEVEL" | translate }}</label>
            <mat-chip>{{ zone.zoneLevel }}</mat-chip>
        </div>
        <mat-chip *ngIf="zone.zoneStatus"
                  [ngClass]="{
                            'green': zone.zoneStatus.toLowerCase() === 'available',
                            'warn': zone.zoneStatus.toLowerCase() === 'occupied',
                            'yellow': zone.zoneStatus.toLowerCase() === 'maintenance'
                            }">
            {{ zone.zoneStatus | uppercase | translate }}
        </mat-chip>
    </div>
    <div *ngIf="zone?.parent" class="zone-info">
        <div class="zone-level">
            <label>{{ "ZONE_PARENT" | translate }}</label>
            <mat-chip>{{ getParentZone() | async }}</mat-chip>
        </div>
    </div>

    <div *ngIf="zone.associatedFamiliesMapped?.length"
         class="content-vertical">
        <label>{{ "CAPABILITIES" | translate }}</label>
        <mat-chip-set>
            <mat-chip *ngFor="let family of zone.associatedFamiliesMapped"
                      [matTooltip]="family.name">
                {{ family.name }}
            </mat-chip>
        </mat-chip-set>
    </div>
    <div *ngIf="zone.owner"
         class="content-vertical">
        <label>{{ "OWNER" | translate }}</label>
        <mat-chip-set>
            <mat-chip class="primary"
                      [matTooltip]="zone.owner">{{ zone.owner }}</mat-chip>
        </mat-chip-set>
    </div>
    <div *ngIf="zone.zoneContact"
         class="content-vertical">
        <label>{{ "CONTACT" | translate }}</label>
        <span>{{ zone.zoneContact }}</span>
    </div>
</div>
