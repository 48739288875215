<div class="toolbar">
  <mat-button-toggle-group name="scale"
                           aria-label="scale"
                           data-color="primary"
                           [value]="selectedScale"
                           (change)="onScaleChange($event)">
    <mat-button-toggle value="values">{{ 'VALUES' | translate }}</mat-button-toggle>
    <mat-button-toggle value="percent">{{ 'PERCENTAGE' | translate }}</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<!-- Family -->
<app-bar-family-in-out [dataKPI]="dataKPIinout"
                       [scale]="selectedScale"></app-bar-family-in-out>

<!-- Assets -->
<app-bar-asset-in-out [dataKPI]="dataKPIinout"
                      [scale]="selectedScale"
                      (chartClicked)="onAssetChartClicked($event)"></app-bar-asset-in-out>

<ng-container *ngIf="(indexAssetClicked | typeOf) === 'number'; else selectElementTemplate">
  <!-- Cycles -->
  <app-bar-cycle-in-out [dataKPI]="dataKPIinout"
                        [scale]="selectedScale"
                        [selectedAssetIndex]="indexAssetClicked"></app-bar-cycle-in-out>
</ng-container>

<ng-template #selectElementTemplate>
    <app-more-kpi></app-more-kpi>
</ng-template>
