/**
 * DO NOT IMPORT SOMETHING FROM ANGULAR, DO NOT SUPPORT DOM / ELEMENT
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { FilterFormModel, FilterOption, FilterOptions } from 'src/app/models/filter-form.model';
import { AssetLoadedStatus } from 'src/app/pipes/_shared/_pure-transform';

export interface WorkerDataInput {
    data: {
        filterFormEntries: FilterFormModel[];
        dataSource: any[];
        filterOptions: { [key: string]: any[] };
        hasClickedApplyFilterButton?: boolean;
    };
}

export declare interface KeyValue<K, V> {
    key: K;
    value: V;
}
export class FilterStatic {

    public static workerProcess({ data }: WorkerDataInput) {
        const { filterFormEntries, dataSource, filterOptions, hasClickedApplyFilterButton } = data;
        const dataOutput = this.filterDataSource(filterFormEntries, dataSource, filterOptions);
        return { ...dataOutput, hasClickedApplyFilterButton: hasClickedApplyFilterButton };
    }

    /**
     * Filter the datasource based on the filter select options selected
     */
    public static filterDataSource(
        filterFormEntries: FilterFormModel[],
        dataSource: any[],
        filterOptions: { [key: string]: any[] },
    ) {

        let dataSourceFiltered: any[] = [];

        // handle the case when no filter option is selected, return all the data source
        if (!filterFormEntries.some(entry => !isEqual(filterOptions[entry.name], []))) {
            dataSourceFiltered = dataSource;
        } else {
            dataSourceFiltered = this._getDataListFiltered(dataSource, filterOptions, filterFormEntries);

        }
        return {
            dataSourceFiltered
        };
    }

    /**
     * @returns an array of options prefiltered based on the selected options
     */
    public static getFilterOptions(entry: FilterFormModel, dataSource: any[], filterFormEntries: FilterFormModel[], selectedFilterOptions: FilterOptions) {
        const _selectedFilterOptions = cloneDeep(selectedFilterOptions);

        // Do not take in account options selected from highers filter hierarchy than the current entry,
        // in order to keep all filter options for the corresponding mat-select
        for (const selectedOptions in _selectedFilterOptions) {
            if (filterFormEntries.find(item => item.name === selectedOptions).hierarchyOrder >= entry.hierarchyOrder) {
                delete _selectedFilterOptions[selectedOptions];
            }
        }

        const dataFiltered = FilterStatic._getDataListFiltered(dataSource, _selectedFilterOptions, filterFormEntries);
        let filterOptions: FilterOption[];
        if (entry.staticValues) {
            filterOptions = FilterStatic._fillStaticDataList(entry);
        } else {
            filterOptions = FilterStatic.fillDynamicDataList(entry, dataFiltered);
        }

        return filterOptions;
    }

    /**
    * Return array from a data source with a specific array path
    */
    public static getArrayFromArrayPath(arrayPath: Array<string>, datum: any): any[] {
        let value = datum[arrayPath[0]];
        for (const data of arrayPath) {
            if (data === arrayPath[0]) {
                //
                continue;
            }
            value = value && value[data];
        }
        return value;
    }

    /**
    * Retreive value from a data source with a specific path
    * @returns value of reached by the path
    */
    public static getValueFromPath(keyPath: string[], datum: any): any {
        let path: any = datum[keyPath[0]];
        for (const data of keyPath) {
            if (data === keyPath[0]) {
                // value already retreived
                continue;
            }
            path = path && path[data]; // return path[data] if exists
        }
        return path;
    }

    /**
    * Retreive key value for a specific entry
    */
    public static getKeyValueToken(entry: FilterFormModel, datum: any): { key: string|number|boolean, value: string, scope?: string }[] {
        // Retreive value from a dataPath
        let key: string | string[];
        let scope: string; // scope retrieve
        if (entry.scopePath) {
            for (const scopePath of entry.scopePath) {
                scope = FilterStatic.getValueFromPath(scopePath, datum);
            }
        }
        for (const keyPath of entry.keyPath) {
            key = FilterStatic.getValueFromPath(keyPath, datum);
        }

        // case of the value is an array
        if (Array.isArray(key)) {
            let value;
            for (const dataPath of entry.dataPath) {
                value = FilterStatic.getValueFromPath(dataPath, datum);
            }
            const result = [];
            for (let i = 0; i < key.length; i++) {
                result.push({
                    key: key[i],
                    value: value[i]
                });
            }
            return result;

        } else { // the value is a single value
            let finalValue = '';
            let separator = '';
            for (const dataPath of entry.dataPath) {
                const value = FilterStatic.getValueFromPath(dataPath, datum);
                // No strict comparison to check both null and undefined
                if (value == null) {
                    continue;
                }
                if (entry.dataPath.length > 1) {
                    finalValue = `${finalValue}${separator}${value}`;
                } else {
                    finalValue = value.toString();
                }
                separator = FilterStatic.calcNextSeparator(separator);
            }
            return [{ key, value: finalValue, scope }];
        }
    }

    /**
    * Prepare standard value for filter options
    */
    private static calcNextSeparator(separator: string) {
        switch (separator) {
            case '':
                return ' | ';
            case ' | ':
                return '/';
            case '/':
                return '/';
            default:
                return '';
        }
    }


    /**
    * Fill dynamic values on auto complete list
    * @param entryName Form entry name
    */
    public static fillDynamicDataList(entry: FilterFormModel, dataSource: any): FilterOption[] {
        const dataForAutoComplete: FilterOption[] = [];
        dataSource.forEach((datum: any) => {
            let finalOptionValue: string;
            if (entry.isSpecificCase) {
                // Manage specific case
                finalOptionValue = FilterStatic.fillSpecificCase(entry, datum);
                if (finalOptionValue && finalOptionValue !== '' && !dataForAutoComplete.some(data => data.value === finalOptionValue)) {
                    dataForAutoComplete.push({ key: finalOptionValue, value: finalOptionValue });
                }
            } else {
                const listToken = [];
                if (entry.arrayDataPath) {
                    // Result to found are in an array, so let's start from the array context
                    const dataContext = FilterStatic.getArrayFromArrayPath(entry.arrayDataPath, datum);
                    if (dataContext && dataContext.length > 0) {
                        for (const arrayData of dataContext) {
                            const keyValue = FilterStatic.getKeyValueToken(entry, arrayData);
                            listToken.push(...keyValue);
                        }
                    }
                } else {
                    // Normal case, we only follow the path
                    const keyValue = FilterStatic.getKeyValueToken(entry, datum);
                    listToken.push(...keyValue);
                }

                for (const token of listToken) {
                    // Don't do strict verification to avoid to check both null and undefined
                    if (token.key != null && token.value != null
                        && !dataForAutoComplete.some(data => data.key === token.key)) {
                        dataForAutoComplete.push({
                            key: token.key, value: token.value, scope: token?.scope
                        });
                    }
                }
            }
        });

        return dataForAutoComplete;
    }

    /**
     * Fill specific cases (filter entris with isSpecificCase attribute)
     */
    public static fillSpecificCase(entry: FilterFormModel, datum: any): string {
        if (entry.name === 'lastKnownLocation' || entry.name === 'plannedLocation' || entry.name === 'confirmedLocation') {
            const zone1: string = datum[entry.dataPath[0][0]] ?
                entry.dataPath[0].reduce((accumulator: string, currentValue: any) => accumulator?.[currentValue], datum) :
                null;
            const zone2: string = datum[entry.dataPath[1][0]] ?
                entry.dataPath[1].reduce((accumulator: string, currentValue: any) => accumulator?.[currentValue], datum) :
                null;
            const zone3: string = datum[entry.dataPath[2][0]] ?
                entry.dataPath[2].reduce((accumulator: string, currentValue: any) => accumulator?.[currentValue], datum) :
                null;
            if (zone3) { return `${zone3} | (${zone1} / ${zone2})`; }
            if (zone2) { return `${zone2} | (${zone1})`; }
            if (zone1) { return zone1; }
        }
    }


    /**
     * Filter the data
     */
    public static _getDataListFiltered(dataSource: any[], filterOptions: { [key: string]: any[] }, filterFormEntries: FilterFormModel[]): any[] {
        // Iterate through data source, and for each one keep it or not after filtering action
        const dataSourceFiltered = structuredClone(dataSource).filter(datum => {
            let test = true;
            for (const filterFormEntry of filterFormEntries) {
                const filterListConst = [...filterOptions[filterFormEntry.name] || []];
                if (!test) {
                    // if value not found in a previous filter then we can remove it
                    return false;
                }
                if (filterListConst.length > 0) { // if there are values in the filter entry
                    if (filterFormEntry.type !== 'datePicker') {
                        if (filterFormEntry.isSpecificCase) {
                            test = FilterStatic._applySpecificCase(test, filterFormEntry, datum, filterListConst);
                        } else {
                            if (filterFormEntry.arrayDataPath) {
                                // Retreive current array
                                const currentArray = FilterStatic.getArrayFromArrayPath(filterFormEntry.arrayDataPath, datum);
                                test = currentArray?.some(FilterStatic._filterArrayData(filterListConst, filterFormEntry));
                                if (test) {
                                    // remove child data in case they are displayed
                                    const filteredArray = currentArray.filter(FilterStatic._filterArrayData(filterListConst, filterFormEntry));
                                    // empty the current array and replace contained items by the filtered ones. Aims to keep the array reference
                                    currentArray.splice(0, currentArray.length);
                                    currentArray.push(...filteredArray);
                                }
                            } else {
                                for (const keyPath of filterFormEntry.keyPath) {
                                    const value = FilterStatic.getValueFromPath(keyPath, datum);
                                    test = filterListConst.some((filterEl: any) => Array.isArray(value) ? value?.includes(filterEl.key) : value?.toString() === filterEl.key);
                                }
                            }

                        }
                    } else if (test) { // Finally, check the date and apply pre filter
                        // If the row date is between the start [0] and the end [1] date filters, keep it in the array
                        test = false;
                        for (const keyPath of filterFormEntry.keyPath) {
                            let path: any = datum[keyPath[0]];
                            for (const data of keyPath) {
                                if (data === keyPath[0]) {
                                    continue;
                                }
                                path = path && path[data];
                            }
                            if (!path) {
                                // If current date is not filled, check the next one (if any).
                                continue;
                            }
                            if (path && moment(path).isBetween(moment(filterListConst[0]), moment(filterListConst[1]))) {
                                test = true;
                                break;
                            } else {
                                test = false;
                                break;
                            }
                        }
                    }
                } // End of If filterListConst.length > 0
            } // End for each loop
            return test;
        }); // End filter function
        return dataSourceFiltered;
    }

    /**
     * Fill static autocomplete
     */
    public static _fillStaticDataList(entry: FilterFormModel): FilterOption[] {
        const dataForAutocomplete = [];
        if (entry.name === 'loadStatus') {
            dataForAutocomplete.push({ key: 'LOADED', value: 'LOADED' });
            dataForAutocomplete.push({ key: 'EMPTY', value: 'EMPTY' });
        } else if (entry.name === 'role') {
            dataForAutocomplete.push({ key: 'owner', value: 'owner' });
            dataForAutocomplete.push({ key: 'manager', value: 'manager' });
            dataForAutocomplete.push({ key: 'none', value: 'none' });
        }
        return dataForAutocomplete;
    }

    /**
     * Return a filter function in order pass it in the Array.some() or Array.filter() function
     * - Return true if the array has the property defined by the filterFormEntry keyPath and if
     *  this property contains some of values from the filterListConst array
     * - Works if the property to be checked is an array of string, a string or a number
     */
    private static _filterArrayData(filterListConst, filterFormEntry) {
        return (val) => {
            return filterListConst.some((filterEl: any) => {
                // handle the case when data type is defferent to array or string
                let dataToTest = val[filterFormEntry.keyPath[0][0]]; // the data to check whether is in the filterListConst
                // here we make sur that the indexOf methode is applicable in order to manage string and array with the same code
                // whithout that this logic doesn't work with numbers
                if (typeof dataToTest !== 'string' && !Array.isArray(dataToTest)) {
                    // if it is not the case, we cast the data into string type
                    dataToTest = dataToTest?.toString();
                }
                // Use index of for array and perfect match for string, avoid possible missmatch for indexOf with string
                return Array.isArray(dataToTest) ? dataToTest?.indexOf(filterEl.key) > -1 : dataToTest === filterEl.key;
            });
        };
    }

    /**
     * Apply filter of a specific filter form entry
     * @param entry Form entry with specific behavior
     * @returns A boolean to know if the data corresponds to the filter
     */
    private static _applySpecificCase(test: boolean, entry: FilterFormModel, datum: any, filterListConst: any): boolean {
        switch (entry.name) {
            case 'lastKnownLocation':
            case 'plannedLocation':
            case 'confirmedLocation':
                test = false;
                // eslint-disable-next-line no-case-declarations
                const zone1: string = datum[entry.dataPath[0][0]] ?
                    entry.dataPath[0].reduce((accumulator: string, currentValue: any) => accumulator?.[currentValue], datum) :
                    null;
                // eslint-disable-next-line no-case-declarations
                const zone2: string = datum[entry.dataPath[1][0]] ?
                    entry.dataPath[1].reduce((accumulator: string, currentValue: any) => accumulator?.[currentValue], datum) :
                    null;
                // eslint-disable-next-line no-case-declarations
                const zone3: string = datum[entry.dataPath[2][0]] ?
                    entry.dataPath[2].reduce((accumulator: string, currentValue: any) => accumulator?.[currentValue], datum) :
                    null;
                if (zone3) { test = filterListConst.some((filterEl: any) => zone3 === filterEl.key || zone2 === filterEl.key || zone1 === filterEl.key); break; }
                if (zone2) { test = filterListConst.some((filterEl: any) => zone2 === filterEl.key || zone1 === filterEl.key); break; }
                if (zone1) { test = filterListConst.some((filterEl: any) => zone1 === filterEl.key); break; }
                break;
            case 'loadStatus':
                if (test) {
                    const loadedStatus = AssetLoadedStatus.transform(datum);
                    const isLoaded = loadedStatus === AssetLoadedStatus.Status.loaded ? 'LOADED' : 'EMPTY';
                    test = filterListConst.some((filterEl: any) => isLoaded === filterEl.key);
                }
                break;
            case 'role':
                test = (filterListConst.some((el) => el.key === 'owner') && datum.isOwner)
                    || (filterListConst.some((el) => el.key === 'manager') && datum.isManager)
                    || (filterListConst.some((el) => el.key === 'none') && !datum.isOwner && !datum.isManager);
                break;
            default:
                break;
        }
        return test;
    }
}
