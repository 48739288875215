<ng-container [ngSwitch]="selectedContent">
    <ng-container *ngSwitchCase="ASSET_POPUP_CONTENTS.loadedElements">
        <app-pop-in-loaded-elements *ngIf="!!this.asset.loadedElements?.length"
                                    [asset]="asset"></app-pop-in-loaded-elements>
    </ng-container>
    <ng-container *ngSwitchCase="ASSET_POPUP_CONTENTS.history">
        <app-pop-in-select-history [asset]="asset"></app-pop-in-select-history>
    </ng-container>
    <ng-container *ngSwitchCase="ASSET_POPUP_CONTENTS.additionalInfo">
        <app-pop-in-asset-additional-info [asset]="asset"></app-pop-in-asset-additional-info>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <app-pop-in-asset [asset]="asset"></app-pop-in-asset>
    </ng-container>
</ng-container>
