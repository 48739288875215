import { Component, Inject } from '@angular/core';
import { Event } from 'src/app/models/event';

@Component({
    selector: 'app-pop-in-history',
    templateUrl: './pop-in-history.component.html',
    styleUrls: ['./pop-in-history.component.scss']
})
export class PopInHistoryComponent {

    constructor(
        @Inject(Event) public assetEvent: Event,
    ) { }
}
