<form class="container"
      [formGroup]="zoneCreateForm">

        <mat-form-field appearance="outline">
            <mat-label>{{ "ZONE_NAME" | translate }}</mat-label>
            <input matInput
                   type="text"
                   placeholder="{{ 'NAME' | translate }}"
                   formControlName="name" />
        </mat-form-field>

        <mat-divider></mat-divider>

        <div *ngIf="zone?.parent">
            <label>{{ "ZONE_PARENT" | translate }}</label>
            <span>{{ getParentZone() | async }}</span>
        </div>

        <div>
            <label>{{ "ZONE_LEVEL" | translate }}</label>
            <span>{{ zone.zoneLevel }}</span>
        </div>

</form>
