import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Site } from '../models/site';
import { CacheDeltaResponse } from '../models/cache';

@Injectable({
    providedIn: 'root'
})
export class SiteService {

    constructor(private http: HttpClient) { }

    /**
     * Get all sites for a specific company
     * @param companyId The id of the company
     * @returns List of Site model
     */
    public getSites(companyId: string): Observable<Site[]> {
        return this.http.get<Site[]>(`${environment.API_ENDPOINT}/companies/${companyId}/sites`);
    }

    /**
     * Get all sites
     */
    public getAllSites(delta?: string): Observable<CacheDeltaResponse<Site>> {
        let params = new HttpParams();

        if (delta !== undefined) {
            params = params.set('delta', delta);
          }

        return this.http.get<CacheDeltaResponse<Site>>(`${environment.API_ENDPOINT}/sites`,
            { params }
        );
    }
}
