import { environment as defaultEnvironment } from './environment.default';

export const environment = {
    ...defaultEnvironment,
    envName: 'int',
    API_ENDPOINT: 'https://way-int.airbus-v.com/api/desktop',
    API_PATH: '/int/api/desktop',
    production: false,
    guideMeActive: true,
    guideMeUrl: 'https://guideme.apps.ocp01.airbus.corp/know/servlet/publicationList?applicationId=WaY_test',
    WS_ENDPOINT: 'wss://hlu9za856g.execute-api.eu-west-1.amazonaws.com/int'
};
