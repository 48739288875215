import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, RendererFactory2, StaticProvider } from '@angular/core';
import Color from '@arcgis/core/Color';
import Graphic from '@arcgis/core/Graphic';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import Polygon from '@arcgis/core/geometry/Polygon';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import CustomContent from '@arcgis/core/popup/content/CustomContent';
import ActionButton from '@arcgis/core/support/actions/ActionButton';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import MapView from '@arcgis/core/views/MapView';
import Sketch from '@arcgis/core/widgets/Sketch';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, filter, groupBy, mergeMap, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import { COLORS, transparentize } from 'src/app/models/scope-management.model';
import { Zone } from 'src/app/models/zone';
import { EsriBase } from './esri-base';
import { PopInZoneMaintenanceComponent } from 'src/app/components/esri-map/pop-in/pop-in-zone-maintenance/pop-in-zone-maintenance.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ZoneEffects } from 'src/app/store/effects/zone.effects';
import { createZone, deleteZone, editZone } from 'src/app/store/actions/zone.action';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogValidationComponent } from 'src/app/components/shared/dialog/dialog-validation/dialog-validation.component';
import { selectZones$, selectZonesLoaded$ } from 'src/app/store/selectors/zone.selector';

// #region Errors
class IntersectionError extends Error {
    private _zones: [Polygon, Polygon];
    public get zones() {
        return this._zones;
    }
    constructor(message: string, zones: [Polygon, Polygon]) {
        super(message);
        this._zones = zones;
    }
}

class SelfIntersectingError extends Error {
    private _zone: Polygon;
    public get zone() {
        return this._zone;
    }
    constructor(message: string, zone: Polygon) {
        super(message);
        this._zone = zone;
    }
}

class ZoneLevelError extends Error {
    private _zone: Zone;
    public get zone() {
        return this._zone;
    }
    constructor(message: string, zone: Zone) {
        super(message);
        this._zone = zone;
    }
}

class MoveOutOfParentError extends Error {
    constructor(
        message: string,
        public drawnZone: Polygon,
        public editedZone: Zone,
        public wrongParent: Zone
    ) {
        super(message);
    }
}

class ChildrenZoneChangedError extends Error {
    constructor(
        message: string,
        public drawnZone: Polygon,
        public editedZone: Zone,
    ) {
        super(message);
    }
}
// #endregion

enum PopupActions {
    cancel = 'cancel',
    save = 'save',
    delete = 'delete',
}

export interface ZoneEditingStatus {
    status: boolean;
    reason?: 'saved'|'cancelled'|'pending'|'deleted';
    zoneEdit?: Zone;
}

@Injectable({
    providedIn: 'root',
})
export class EsriZoneManagementService extends EsriBase {

    public editing$: Observable<ZoneEditingStatus>;

    private _layer: GraphicsLayer;
    private _sketch: Sketch;
    private _map: MapView;
    private _sketchUpdate$ = new Subject<__esri.SketchCreateEvent|__esri.SketchUpdateEvent|__esri.SketchDeleteEvent>();
    private _editedZone: { zone?: Zone; polygon?: Polygon; };
    private _editing$ = new BehaviorSubject<ZoneEditingStatus>({ status: false });
    private _destroyed$ = new Subject<void>();
    private _setPreviousZone: { zone: Zone; polygon: Polygon; };
    private readonly GEOGRAPHIC_WKID = 4326;
    private _zoneGeoPolygons: { zone: Zone; polygon: Polygon; }[];

    /**
     * The Arcgis map.
     * This parameter **must** be set to use the service
     */
    public set map(map: MapView) {
        this._map = map;
        this._sketch.view = map;
        this._map.on('click', (event) => {
            if (this._layer?.graphics.some(
                ({ geometry }) => geometryEngine.intersects(geometry, event.mapPoint)
            )) {
                this._map.openPopup({
                    features: [...this._layer.graphics],
                    shouldFocus: true,
                    updateLocationEnabled: true,
                });
            }
        });
        this.handlePopupActionClick<ActionButton>(this._map, PopupActions.cancel, () => {
            this._cancelCurrentEdition();
        });
        this.handlePopupActionClick<ActionButton>(this._map, PopupActions.save, () => {
            this._saveZone();
        });
        this.handlePopupActionClick<ActionButton>(this._map, PopupActions.delete, () => {
            this._handleDeleteZone();
        });
        this._map.ui.add(this._sketch, 'bottom-right');
    }

    constructor(
        private store: Store,
        private _snackbar: SnackbarComponent,
        private _translate: TranslateService,
        private _zoneEffects: ZoneEffects,
        private _dialog: MatDialog,
        protected rendererFactory: RendererFactory2,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected appRef: ApplicationRef,
        protected injector: Injector,
    ) {
        super(rendererFactory, componentFactoryResolver, appRef, injector);
        this.editing$ = this._editing$.pipe(
            shareReplay(1),
        );
        this._sketch = new Sketch({
            id: 'sketch',
            visible: false,
            creationMode: 'update',
            snappingOptions: {
                selfEnabled: true,
            },
            defaultUpdateOptions: {
                highlightOptions: { enabled: false },
                multipleSelectionEnabled: false,
                enableZ: false,
            },
            visibleElements: {
                duplicateButton: false,
                createTools: {
                    point: false,
                    circle: false,
                    polyline: false,
                    rectangle: false,
                    polygon: true,
                },
                selectionTools: {
                    'lasso-selection': false,
                    'rectangle-selection': false,
                },
                settingsMenu: false,
            },
        });
        this._sketch.on('create', (event) => this._sketchUpdate$.next(event));
        this._sketch.on('delete', (event) => this._sketchUpdate$.next(event));
        this._sketch.on('update', (event) => this._sketchUpdate$.next(event));
        this.store.select(selectZonesLoaded$).pipe(
            filter(loaded => loaded),
            take(1),
            switchMap(() => this.store.select(selectZones$))
        ).subscribe(
            zones => {
                zones.sort((a, b) => +b.zoneLevel - +a.zoneLevel);
                this._zoneGeoPolygons = zones.map(
                    zone => ({
                        zone,
                        polygon: new Polygon({
                            rings: zone.coordinates.coordinates,
                            spatialReference: {
                                wkid: this.GEOGRAPHIC_WKID,
                            },
                        }),
                    })
                );
            }
        );
        this._sketchUpdate$.pipe(
            groupBy(({ type }) => type),
            mergeMap(groups$ => groups$.pipe(
                debounceTime(200),
            )),
            takeUntil(this._destroyed$),
        ).subscribe(
            (event) => {
                switch (event.type) {
                    case 'create':
                        this._onCreate(event);
                        break;
                    case 'update':
                        this._onUpdate(event);
                        break;
                    case 'delete':
                        this._onDelete(event);
                        break;
                }
            }
        );
        this.editing$.pipe(
            takeUntil(this._destroyed$),
        ).subscribe(
            ({ status }) => {
                if (!status) {
                    this._editedZone = null;
                }
            }
        );
    }

    /**
     * Handle the errors related to the zones
     * @param error The error
     * @param graphic The graphic related to the error
     */
    private _handleZoneErrors(error: Error, graphic: Graphic): void {
        this._map.closePopup();
        let errorText: string;
        if (error instanceof IntersectionError) {
            errorText = this._translate.instant('ZONES_INTERSECTING');
        } else if (error instanceof ZoneLevelError) {
            errorText = this._translate.instant('ZONE_LEVEL_ERROR');
        } else if (error instanceof SelfIntersectingError) {
            errorText = this._translate.instant('ZONE_SELF_INTERSECTING');
        } else if (error instanceof MoveOutOfParentError) {
            errorText = this._translate.instant('ZONE_MOVE_OUT_PARENT');
        } else if (error instanceof ChildrenZoneChangedError) {
            errorText = this._translate.instant('ZONE_CHILDREN_CHANGED_ERROR');
        } else if (error instanceof Error) {
            errorText = error.message;
        }
        if (errorText) {
            this._snackbar.open(errorText, 'red-snackbar');
        }
        this._setZoneSymbol(graphic, true);
        this._setSaveandDeleteButtonStatus(graphic, true);
    }

    /**
     * Handle a new drawing of zone on the map
     * @param event The creation event
     */
    private async _onCreate(event: __esri.SketchCreateEvent): Promise<void> {
        const polygon = event.graphic?.geometry as Polygon;
        this._snackbar.dismiss();
        try {
            switch (event.state) {
                case 'start':
                    this._editing$.next({ status: true });
                    break;
                case 'cancel':
                    this._editing$.next({ status: false, reason: 'cancelled' });
                    break;
                case 'complete':
                    this._editedZone = { zone: new Zone({}) };
                    this._createGraphicAndPopup(event.graphic);
                    break;
                case 'active':
                    this._setZoneSymbol(event.graphic);
                    this._validateZone(polygon);
                    break;
            }
        } catch (error) {
            this._handleZoneErrors(error, event.graphic);
        }
    }

    /**
     * Handle the changes of the drawing on the map
     * @param event The update event
     */
    private async _onUpdate(event: __esri.SketchUpdateEvent): Promise<void> {
        if (event.aborted) {
            return;
        }
        const graphic = event.graphics[0];
        const polygon = graphic?.geometry;
        if (polygon instanceof Polygon) {
            try {
                this._setZoneSymbol(graphic, false);
                this._setSaveandDeleteButtonStatus(graphic, false, false);
                const parentZone = this._getParentZone(polygon);
                this._validateZone(polygon, parentZone);
                if (!this._editedZone) {
                    this._editedZone = {};
                }
                this._editedZone.zone ??= new Zone({});
                this._editedZone.zone.zoneLevel = ((+parentZone?.zoneLevel || 0) + 1).toString();
                this._editedZone.zone.parent = parentZone?.id;
                await this._map.openPopup({
                    features: event.graphics,
                    location: polygon.centroid,
                    updateLocationEnabled: true,
                    shouldFocus: true,
                });
            } catch (error) {
                this._handleZoneErrors(error, graphic);
                this._cancelCurrentEdition();
            }
        }
    }

    /**
     * Handle the deletion of a drawing on the map
     * @param event The deletion event
     */
    private _onDelete(event: __esri.SketchDeleteEvent): void {
        this._map.closePopup();
        if (this._editedZone?.zone?.id) {
            // Restore graphic awaiting user validation
            this._layer.addMany(event.graphics);
            this._handleDeleteZone();
        } else {
            this._editing$.next({ status: false, reason: 'cancelled' });
        }
    }

    /**
     * Initialise the graphic layer and its dependencies / handlers
     */
    private _initLayer(): void {
        if (!this._layer) {
            this._layer = new GraphicsLayer({});
            this._sketch.layer = this._layer;
            this._layer.graphics.on('after-add', () => this._sketch.visibleElements.createTools.polygon = false);
            this._layer.graphics.on('after-remove', () => this._sketch.visibleElements.createTools.polygon = true);
        }
    }

    /**
     * Display the zone drawing tool
     */
    public displayTool(): void {
        if (!this._map) {
            throw new EvalError('map must be defined');
        }
        this._initLayer();
        this._map.map.layers.push(this._layer);
        this._sketch.visible = true;
    }

    /**
     * Remove the zone drawing tool and cancel edition if it's in progress
     */
    public hideTool(): void {
        this._sketch.visible = false;
        // If edit still in progress => cancel
        this._editing$.pipe(
            take(1),
            filter(({ status }) => status === true),
        ).subscribe(
            () => this._editing$.next({ status: false, reason: 'cancelled' })
        );
        this._layer.removeAll();
        this._map.map.layers.remove(this._layer);
        this._sketch.cancel();
    }

    /**
     * Edit an existing zone. The zone will not be removed from its current layer.
     * @param zoneId The zone id to edit
     */
    public async editZone(zoneId: string): Promise<void> {
        if (this._layer.graphics.length > 0) {
            this._snackbar.open(this._translate.instant('ZONES_ALREADY_CHANGE_IN_PROGRESS'), 'orange-snackbar');
            throw new Error('Already in creation mode');
        }
        const zoneToEditIndex = this._zoneGeoPolygons.findIndex(
            ({ zone }) => zone.id === zoneId
        );
        if (zoneToEditIndex === -1) {
            // No zone to edit, creation mode
            this._snackbar.open(this._translate.instant('ZONE_NOT_IDENTIFIED_THEN_CREATE'), 'orange-snackbar');
            throw new Error('Zone not found');
        }
        const [zoneToEdit] = this._zoneGeoPolygons.splice(zoneToEditIndex, 1);
        this._editedZone = zoneToEdit;
        this._setPreviousZone = { ...zoneToEdit };
        const graphic = new Graphic({
            geometry: webMercatorUtils.geographicToWebMercator(zoneToEdit.polygon),
        });
        this._layer.add(graphic);
        await this._createGraphicAndPopup(graphic);
        this._map.closePopup();
        this._editing$.next({ status: true });
    }

    /**
     * Get the zone containing the polygon. It will return `undefined` if no parent is found.
     * @param polygon The zone polygon
     */
    private _getParentZone(polygon: Polygon): Zone|undefined {
        const geoPolygon = webMercatorUtils.webMercatorToGeographic(polygon);
        // Check zones level 3 first
        for (const { zone, polygon: zonePolygon } of this._zoneGeoPolygons) {
            const containedWithin = geometryEngine.within(geoPolygon, zonePolygon);
            if (containedWithin) {
                return zone;
            }
        }
    }

    /**
     * Validate the zone pattern. Throws an error in case of issue.
     * @param polygon The polygon to validate
     * @param parent The parent zone
     */
    private _validateZone(polygon: Polygon, parent?: Zone): void {
        const geoPolygon = webMercatorUtils.webMercatorToGeographic(polygon) as Polygon;
        // Sort zones by descending zone level
        this._zoneGeoPolygons.sort(
            (a, b) => +b.zone.zoneLevel - +a.zone.zoneLevel
        );
        parent ??= this._getParentZone(polygon);
        if (+parent?.zoneLevel === 3) {
            throw new ZoneLevelError('zone too deep', parent);
        }
        if (polygon.isSelfIntersecting || polygon.rings.length > 1) {
            throw new SelfIntersectingError('zone self-intersecting', polygon);
        }
        // If it's a zone edit, check moving rules
        if (this._editedZone?.zone?.id) {
            const children = this._zoneGeoPolygons.filter(
                ({ zone }) => zone.parent === this._editedZone?.zone?.id
            ).map(
                ({ polygon: zonePolygon }) => zonePolygon
            );
            if (children.some(
                child => {
                    return !geometryEngine.contains(geoPolygon, child);
                }
            )) {
                throw new ChildrenZoneChangedError('children zones impacted', polygon, this._editedZone.zone);
            }
        }
        // Check zones level 3 first
        for (const { zone, polygon: zonePolygon } of this._zoneGeoPolygons) {
            const intersects = geometryEngine.intersect(geoPolygon, zonePolygon) && !geometryEngine.contains(geoPolygon, zonePolygon);
            if (intersects) {
                if (zone.id === parent?.id) {
                    // If intersection correspond to the parent, that's expected
                    return;
                } else {
                    throw new IntersectionError('zones intersection', [zonePolygon, geoPolygon]);
                }
            }
        }
    }

    /**
     * Set the symbol for the zone
     * @param graphic The graphic
     * @param error Is it in error? default value: `false`
     */
    private _setZoneSymbol(graphic: Graphic, error = false) {
        graphic.symbol = new SimpleFillSymbol({
            color: new Color(error ? transparentize(COLORS.red, 0.8) : transparentize(COLORS.white, 0.5)),
            outline: {
                color: new Color(error ? COLORS.red : COLORS.gray),
                width: 2,
            },
        });
    }

    /**
     * Set the save button status in the popup
     * @param graphic The source graphic of the popup
     * @param disabled Is the save disabled? default value: `false`
     * @param busy Is the save busy? default value `false`
     */
    private _setSaveandDeleteButtonStatus(graphic: Graphic, disabled = false, busy = false) {
        graphic.popupTemplate?.actions.map(
            (action) =>  {
                if ((action.id === PopupActions.delete) || (action.id === PopupActions.save)) {
                action?.set({ disabled, busy });
            }
            }
        );
    }

    /**
     * Set the symbol and popup of the graphic
     * @param graphic The drawn graphic
     */
    private async _createGraphicAndPopup(graphic: Graphic) {
        this._setZoneSymbol(graphic);
        graphic.popupTemplate = new PopupTemplate({
            outFields: ['*'],
            title: this._editedZone?.zone?.id ? this._translate.instant('DIALOG_EDIT_ZONE') : this._translate.instant('ZONE_CREATE_NEW'),
            content: () => {
                const providers: StaticProvider[] = [
                    { provide: Zone, useValue: this._editedZone.zone },
                ];
                const injector = Injector.create({
                    providers,
                    parent: this.injector,
                });
                const popupContent = new CustomContent({
                    outFields: ['*'],
                    creator: () => this.createElementWithAngularComponent(this._map, PopInZoneMaintenanceComponent, injector, true)
                });
                return [popupContent];
            },
            actions: [
                new ActionButton({
                    id: PopupActions.cancel,
                    title: this._translate.instant('CANCEL'),
                    icon: 'circle-disallowed',
                }),
                new ActionButton({
                    id: PopupActions.save,
                    title: this._translate.instant('DIALOG_SAVE_OPTION'),
                    icon: 'save',
                }),
            ],
            overwriteActions: true,
        });
        if (this._editedZone?.zone?.id) {
            graphic.popupTemplate.actions.add(
                new ActionButton({
                    id: PopupActions.delete,
                    title: this._translate.instant('DELETE'),
                    icon: 'trash',
                })
            );
        }
    }

    /**
     * Cancel the current drawing
     */
    private _cancelCurrentEdition() {
        if (this._editedZone?.zone?.id) {
            this._zoneGeoPolygons.push(Object.assign(this._editedZone, this._setPreviousZone));
            this._zoneGeoPolygons.sort((a, b) => +b.zone.zoneLevel - +a.zone.zoneLevel);
            this._editedZone = null;
            this._setPreviousZone = null;
        }
        this._sketch.delete();
    }

    /**
     * Save the drawn zone in the database
     */
    private _saveZone() {
        const graphic = this._layer.graphics?.getItemAt(0);
        if (!graphic) {
            throw new Error('No graphic to save');
        }
        this._setSaveandDeleteButtonStatus(graphic, true, true);
        this._zoneEffects.effectSubject.pipe(
            take(1),
        ).subscribe((result) => {
            this._setActionInProgressState(PopupActions.save, false);
            if (result.result === 'success') {
                this._map.closePopup();
                this._sketch.delete();
                this._layer.removeAll();
                this._editing$.next({ status: false, reason: 'saved', zoneEdit: result.zone });
            }
        });
        const { id, name, parent, zoneLevel } = this._editedZone?.zone ?? {};
        let polygon = graphic.geometry.clone() as Polygon;
        if (polygon.spatialReference.isWebMercator) {
            polygon = webMercatorUtils.webMercatorToGeographic(polygon) as Polygon;
        }
        const coordinates: Zone['coordinates'] = {
            type: polygon.type,
            coordinates: polygon.rings,
        };
        if (this._editedZone?.zone?.id) {
            this.store.dispatch(editZone({
                payload: { id, name, parent, zoneLevel, coordinates },
            }));
            this._zoneGeoPolygons.push(Object.assign(this._editedZone, this._setPreviousZone));
        } else {
            this.store.dispatch(createZone({
                payload: { name, parent, zoneLevel, coordinates },
            }));
        }
        this._setActionInProgressState(PopupActions.save, true);
    }

    /**
     * Open the validation popup in order to delete the alert
     * @param zone Zone to delete
     * @param graphic Geometry which is to be deleted.
     */
    public _handleDeleteZone(): void {
        const graphic = this._layer.graphics?.getItemAt(0);
        if (!graphic) {
            throw new Error('No graphic to delete');
        }
        if (!this._editedZone?.zone?.id) {
            return this._cancelCurrentEdition();
        }
        if (this._zoneGeoPolygons.some(
            ({ zone }) => zone.parent === this._editedZone?.zone?.id
        )) {
            this._snackbar.open(this._translate.instant('ZONE_LEVEL_NOT_DELETED'), 'red-snackbar');
            return;
        }
        const dialogRef: MatDialogRef<DialogValidationComponent> = this._dialog.open(DialogValidationComponent, {
            width: '250px',
            data: {
                title: this._translate.instant('DELETE'),
                textContent: this._translate.instant('DIALOG_DELETE_VALIDATION', { item: this._editedZone?.zone?.name }),
                validateText: this._translate.instant('DELETE'),
                cancelText: this._translate.instant('CANCEL'),
            }
        });
        dialogRef.componentInstance.onValidateEvent.pipe(
            take(1),
        ).subscribe(async() => {
            dialogRef.close();
            this._deleteZone(this._editedZone?.zone);
            this._setSaveandDeleteButtonStatus(graphic, true, true);
        });
    }

    /**
     * Execute the delete process
     * @param alert The alert to delete
     * @param dialogRef The generic validation dialog component
     */
    private _deleteZone(zone: Zone) {
        this._setActionInProgressState(PopupActions.delete, true);
        this.store.dispatch(deleteZone({ zoneId: zone.id }));
        this._zoneEffects.effectSubject.pipe(
            take(1),
        ).subscribe((result) => {
            this._setActionInProgressState(PopupActions.delete, false);
            if (result.result === 'success') {
                const text = this._translate.instant('BANNER_SUCCESS_DELETE', { item: zone.name });
                this._snackbar.open(text, 'green-snackbar', 5000);
                this._map.closePopup();
                this._sketch.delete();
                this._layer.removeAll();
                this._editing$.next({ status: false, reason: 'deleted' });
            }
        });
    }

    /**
     * Set the given action button at busy state (display spinner) and disable other ones
     * or enable all actions with no busy state
     * @param actionId The id corresponding to the action button
     * @param busy The busy state of the given action
     */
    private _setActionInProgressState(actionId: PopupActions, busy: boolean): void {
        this._map.popup.actions.forEach(
            action => {
                if (action.id === actionId) {
                    action.active = busy;
                }
                action.disabled = busy;
            }
        );
    }

    /**
     * destroy
     */
    public destroy() {
        this._layer.destroy();
        this._layer = null;
        this._sketch.destroy();
        this._sketch = null;
        this._destroyed$.next();
    }
}
