import { HttpClient, HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { inflate } from 'pako';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { APIResponse, HttpMethod } from 'src/app/models/dev-tools.model';
import { ApiBuffer } from 'src/app/models/utils';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-apitester',
    templateUrl: './api-test.component.html',
    styleUrls: ['./api-test.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class APITesterComponent implements OnInit {
    public readonly METHODS = Object.entries(HttpMethod).filter(
        ([key]) => isNaN(+key)
    ).map(
        ([key, value]) => ({ key, value })
    );
    public readonly HttpMethod = HttpMethod;
    public isLoading = false;
    public apiTestForm: FormGroup<{
        url: FormControl<string>;
        token: FormControl<string>;
        method: FormControl<HttpMethod>;
        payload: FormControl<string>;
    }>;
    public apiTestResult: FormGroup<{
        apiResponse: FormControl<APIResponse>;
        body: FormControl<string>;
    }>;
    public hide = true;

    constructor(
        private _http: HttpClient,
        private _cd: ChangeDetectorRef,
        private _fb: FormBuilder,
        private _localStorageService: LocalStorageService,
    ) { }

    ngOnInit(): void {
        this._localStorageService.fetchLocalStorageValues$().pipe(
            filter(({ key }) => key === 'token'),
        ).subscribe(
            token => {
                this.apiTestForm = this._fb.group({
                    url: this._fb.control(environment.API_ENDPOINT, [Validators.required]),
                    token: this._fb.control(token.value, [Validators.required]),
                    method: this._fb.control(HttpMethod.GET, [
                        Validators.required,
                        Validators.pattern(new RegExp(`^${Object.values(HttpMethod).filter(v => !isNaN(+v)).join('|')}$`)),
                    ]),
                    payload: this._fb.control(''),
                });
                this.apiTestForm.controls.method.valueChanges.subscribe(
                    (method) => this._handleMethodChange(method)
                );
            }
        );
        this.apiTestResult = this._fb.group({
            apiResponse: this._fb.control(null),
            body: this._fb.control(null),
        });
    }

    private _handleMethodChange(method: HttpMethod): void {
        if (method === HttpMethod.GET || method === HttpMethod.OPTIONS) {
            this.apiTestForm.patchValue({
                payload: null,
            });
        } else {
            this.apiTestForm.patchValue({
                payload: JSON.stringify({}),
            });
        }
        this._cd.markForCheck();
    }

    public formatePayload() {
        this.apiTestForm.patchValue({
            payload: JSON.stringify(JSON.parse(this.apiTestForm.value.payload), null, 4),
        });
        this._cd.markForCheck();
    }

    public sendRequest() {
        const method = this.apiTestForm.controls.method.value;
        let bindMethodWithHttp;
        this.isLoading = true;
        switch (method) {
            case HttpMethod.GET:
                bindMethodWithHttp = this._http.get.bind(this._http);
                break;
            case HttpMethod.POST:
                bindMethodWithHttp = this._http.post.bind(this._http);
                break;
            case HttpMethod.PUT:
                bindMethodWithHttp = this._http.put.bind(this._http);
                break;
            case HttpMethod.DELETE:
                bindMethodWithHttp = this._http.delete.bind(this._http);
                break;
            case HttpMethod.OPTIONS:
                bindMethodWithHttp = this._http.options.bind(this._http);
                break;
            default:
                bindMethodWithHttp = this._http.get.bind(this._http);
        }
        const requestOption = {
            observe: 'response'
        };
        const httpRequest: Observable<HttpResponse<HttpResponseBase>> = this.apiTestForm.controls.payload.value
            ? bindMethodWithHttp(this.apiTestForm.controls.url.value, this.apiTestForm.controls.payload.value, requestOption)
            : bindMethodWithHttp(this.apiTestForm.controls.url.value, requestOption);
        this.apiTestResult.patchValue({ apiResponse: null, body: null });
        httpRequest.subscribe({
            next: response => {
                this._prepareAPIResponse(response.body, response.status);
            },
            error: (error: HttpErrorResponse) => {
                this._prepareAPIResponse(error.error, error.status);
            },
            complete: () => {
                this.isLoading = false;
            },
        });
    }

    /**
     * prepare API Respone
     * @param response API respone
     * @param status API status
     */
    private _prepareAPIResponse(response: HttpResponseBase | HttpErrorResponse | ApiBuffer, status: number) {
        const apiResponse: APIResponse = {
            status: status,
            class: status === 200 ? 'api-success' : 'api-error'
        };
        if (response.type === 'Buffer') {
            response = JSON.parse(inflate((response as ApiBuffer).data, { to: 'string' }));
        }
        this.apiTestResult.controls.body.setValue(JSON.stringify(response, null, 4));
        this.apiTestResult.controls.apiResponse.setValue(apiResponse);
        this._cd.markForCheck();
    }

}
