<div class="groups">
    <mat-chip-set #groupsList
                   role="list"
                   [attr.aria-label]="'GROUPS' | translate">
        <ng-container *ngFor="let group of groups$ | async as groups; index as i">
            <mat-chip *ngIf="i < qtyGroupsToDisplay"
                      (removed)="onRemoveGroup(group)"
                      [disabled]="groupsLoading || disabled"
                      [matTooltip]="'REQUEST_GROUP_STATUS_TEXT' | translate:{groupName:group.name, managerEmail:group.managerEmail}"
                      [matTooltipDisabled]="!group.requested">
                <fa-icon *ngIf="group.requested"
                         icon="paper-plane">
                   </fa-icon>
                <span [ngClass]="{'draft': group.requested}">{{ group.name }}</span>
                <button matChipRemove
                        *ngIf="!disabled && (
                            (!group.requested && action === ACTIONS.assign) || (group.requested && action === ACTIONS.request)
                        )">
                    <fa-icon icon="times"></fa-icon>
                </button>
            </mat-chip>
            <mat-chip *ngIf="i === qtyGroupsToDisplay"
                      [matTooltip]="groups | slice:qtyGroupsToDisplay | extractPropertyPipe: 'name' | join:''">&#8230;</mat-chip>
        </ng-container>
    </mat-chip-set>
    <ng-container *ngIf="groupsLoading; else elseTemplate">
        <div class="loading-message">
            <mat-progress-spinner color="primary"
                                  matSuffix
                                  mode="indeterminate"
                                  diameter="16"
                                  class="loading-indicator">
            </mat-progress-spinner>
            <span>{{ 'LOADING_GROUP_OF_USER' | translate }}&hellip;</span>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <ng-container [ngSwitch]="action">
            <!-- Assign a group -->
            <ng-container *ngIf="action === ACTIONS.assign">
                <mat-form-field appearance="outline"
                           *ngIf="(groups$ | async).length === 0">
                    <mat-label>{{ 'ADD_GROUP' | translate }}</mat-label>
                    <input #groupInput
                           matInput
                           [formControl]="groupInputForm"
                           [matAutocomplete]="allGroupsList">
                    <button mat-icon-button
                            *ngIf="groupInputForm.value"
                            matSuffix
                            [disabled]="groupsLoading || disabled"
                            (click)="clearGroupInput()">
                        <fa-icon icon="times"></fa-icon>
                    </button>
                </mat-form-field>
            </ng-container>
            <!-- Request a group -->
            <ng-container *ngIf="action === ACTIONS.request">
                <ng-container *ngIf="(groups$ | async).length === 0;">
                    <button mat-stroked-button
                            color="primary"
                            (click)="raiseARequestForGroup()"
                            [disabled]="disabled">
                        <fa-icon icon="plus-circle"></fa-icon>
                        {{ "REQUEST_A_GROUP" | translate }}
                    </button>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <i style="display: none;">
                    No action defined
                </i>
            </ng-container>
        </ng-container>
    </ng-template>
    <mat-autocomplete #allGroupsList="matAutocomplete"
                      (optionSelected)="selectGroup($event)">
        <mat-option *ngFor="let group of allGroups$ | async"
                    [value]="group">
            {{ group.name }}
        </mat-option>
    </mat-autocomplete>
</div>
