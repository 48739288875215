import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Asset } from 'src/app/models/asset';

export enum AssetPopupContents {
    main = 'asset-main',
    history = 'asset-history',
    loadedElements = 'asset-loaded',
    additionalInfo = 'asset-additional-info',
}


export class AssetContainerSelector {
    content$: BehaviorSubject<AssetPopupContents> = new BehaviorSubject(AssetPopupContents.main);
    constructor() { }
    public select(content: AssetPopupContents) {
        this.content$.next(content);
    }
}

@Component({
    selector: 'app-pop-in-asset-container',
    templateUrl: './pop-in-asset-container.component.html',
    styleUrls: ['./pop-in-asset-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopInAssetContainerComponent implements OnInit, OnDestroy {

    public selectedContent: AssetPopupContents = AssetPopupContents.main;
    public readonly ASSET_POPUP_CONTENTS = AssetPopupContents;

    private _subscription = new Subscription();

    constructor(
        @Inject(Asset) public asset: Asset,
        @Inject(AssetContainerSelector) private _selector: AssetContainerSelector,
        private _cd: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this._subscription.add(
            this._selector.content$.subscribe(
                content => {
                    this.selectedContent = content;
                    this._cd.markForCheck();
                }
            )
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
}
