<div class="popin-container">
    <mat-chip-set class="signal-container">
        <mat-chip class="{{assetEvent.source | lowercase }}">
            {{ assetEvent.source | eventSourceName | uppercase }}
        </mat-chip>
        <mat-chip class="{{assetEvent.state | lowercase }}">
            {{ assetEvent.state | uppercase | translate | uppercase }}
        </mat-chip>
    </mat-chip-set>
</div>
