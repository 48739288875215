<!-- Assets -->
<app-bar-asset-loop [dataKPI]="dataKPIloop"
                    (chartClicked)="onAssetChartClick($event)"></app-bar-asset-loop>

<!-- Cycles -->
<ng-container *ngIf="(indexAssetClicked | typeOf) === 'number'; else selectElementTemplate">

  <app-bar-cycle-loop [dataKPI]="dataKPIloop"
                      [selectedAssetIndex]="indexAssetClicked"
                      (chartClicked)="onCycleChartClick($event)"></app-bar-cycle-loop>

  <ng-container *ngIf="(indexCycleClicked | typeOf) === 'number'; else selectElementTemplate">
    <!-- CheckBox for zone 1 and 2 -->
    <div class="container-checkbox-zones-levels">
      <mat-checkbox [(ngModel)]="zoneLevel2"
                    color="primary"
                    (change)="onCheckBoxChange()">
        <span class="checkbox-label">{{ 'KPI_CHECK_BOX_LEVEL_2' | translate }}</span>
      </mat-checkbox>
      <span class="description">{{ 'KPI_CHECK_BOX_LEVEL_2_DESC' | translate }}</span>
    </div>

    <!-- Zone 1 -->
    <app-bar-zone-loop [dataKPI]="dataKPIloop"
                       [selectedAssetIndex]="indexAssetClicked"
                       [selectedCycleIndex]="indexCycleClicked"
                       [zoneLevel]="zoneLevel2 ? 2 : 1"></app-bar-zone-loop>

  </ng-container>

</ng-container>

<ng-template #selectElementTemplate>
    <app-more-kpi></app-more-kpi>
</ng-template>
