import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ArrayType } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { max, mean, min } from 'mathjs';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { KpiAchievement, KpiDataFormat } from '../models/kpiform';
import { SnackbarComponent } from '../components/shared/snackbar/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { ErrorTranslationService } from './error-translation.service';

class KpiZoneIn1 {
    zoneIn1Name: string;
    zoneIn1Level: number;
    zoneIn1Order: number;
    zoneIn1TimePassedIn: number;
    zoneIn1ZonesIn2: ArrayType;

    constructor(zoneIn1Name: string, zoneIn1Level: number, zoneIn1Order: number, zoneIn1TimePassedIn: number,
        zoneIn1ZonesIn2: ArrayType) {
        this.zoneIn1Name = zoneIn1Name;
        this.zoneIn1Level = zoneIn1Level;
        this.zoneIn1Order = zoneIn1Order;
        this.zoneIn1TimePassedIn = zoneIn1TimePassedIn;
        this.zoneIn1ZonesIn2 = zoneIn1ZonesIn2;
    }
    getZoneIn1Name(): string {
        return this.zoneIn1Name;
    }

    getZoneIn1ZonesIn2() {
        return this.zoneIn1ZonesIn2;
    }
}

export class KpiZone {
    zoneName: string;
    zoneLevel: number;
    zoneOrder: number;
    zoneTimePassedIn: number;
    zoneZonesIn1: Array<KpiZoneIn1>;

    constructor(zoneName: string, zoneLevel: number, zoneOrder: number, zoneTimePassedIn: number,
        zoneZonesIn1: Array<KpiZoneIn1>) {
        this.zoneName = zoneName;
        this.zoneLevel = zoneLevel;
        this.zoneOrder = zoneOrder;
        this.zoneTimePassedIn = zoneTimePassedIn;
        this.zoneZonesIn1 = zoneZonesIn1;

    }
    getZoneName(): string {
        return this.zoneName;
    }

    getZoneZonesIn1(): Array<KpiZoneIn1> {
        return this.zoneZonesIn1;
    }
    getTimePassedIn() {
        return this.zoneTimePassedIn;
    }

}

export class KpiCycle {
    cycleStartDate: number;
    cycleCycleOrder: number;
    cycleCycleTime: number;
    cycleMovingTime: number;
    cycleUnknownTime: number;
    cycleStaticTime: number;
    cycleInTime: number;
    cycleOutTime: number;
    cycleZones: Array<KpiZone>;

    constructor(cycleStartDate: number, cycleCycleOrder: number, cycleCycleTime: number, cycleMovingTime: number, cycleUnknownTime: number, cycleStaticTime: number,
        cycleInTime: number, cycleOutTime: number, cycleZones: Array<KpiZone>) {
        this.cycleStartDate = cycleStartDate;
        this.cycleCycleOrder = cycleCycleOrder;
        this.cycleCycleTime = cycleCycleTime;
        this.cycleMovingTime = cycleMovingTime;
        this.cycleUnknownTime = cycleUnknownTime;
        this.cycleStaticTime = cycleStaticTime;
        this.cycleInTime = cycleInTime;
        this.cycleOutTime = cycleOutTime;
        this.cycleZones = cycleZones;
    }

    getCycleCycleOrder(): number {
        return this.cycleCycleOrder;
    }

    getCycleZones(): Array<KpiZone> {
        return this.cycleZones;
    }

}

export class KpiAsset {
    assetId: string;
    assetName: string;
    assetNumberOfCycle: number;
    assetMinCycleTime: number;
    assetMdianCycleTime: number;
    assetMeanCycleTime: number;
    assetMaxCycleTime: number;
    assetMovingTime: number;
    assetUnknownTime: number;
    assetStaticTime: number;
    assetInTime: number;
    assetOutTime: number;
    assetCycles: Array<KpiCycle>;
    constructor(assetId: string, assetName: string, assetNumberOfCycle: number, assetMinCycleTime: number,
        assetMdianCycleTime: number, assetMeanCycleTime: number, assetMaxCycleTime: number, assetMovingTime: number, assetUnknownTime: number,
        assetStaticTime: number, assetInTime: number, assetOutTime: number, assetCycles: Array<KpiCycle>) {
        this.assetId = assetId;
        this.assetName = assetName;
        this.assetNumberOfCycle = assetNumberOfCycle;
        this.assetMinCycleTime = assetMinCycleTime;
        this.assetMdianCycleTime = assetMdianCycleTime;
        this.assetMeanCycleTime = assetMeanCycleTime;
        this.assetMaxCycleTime = assetMaxCycleTime;
        this.assetMovingTime = assetMovingTime;
        this.assetUnknownTime = assetUnknownTime;
        this.assetStaticTime = assetStaticTime;
        this.assetInTime = assetInTime;
        this.assetOutTime = assetOutTime;
        this.assetCycles = assetCycles;
    }

    getAssetName(): string {
        return this.assetName;
    }

    getAssetMinCycleTime(): number {
        return this.assetMinCycleTime;
    }

    getAssetMeanCycleTime(): number {
        return this.assetMeanCycleTime;
    }

    getAssetMxCycleTime(): number {
        return this.assetMaxCycleTime;
    }

    getAssetNumberOfCycle(): number {
        return this.assetNumberOfCycle;
    }

    getAssetCycles(): Array<KpiCycle> {
        return this.assetCycles;
    }
    getAssetId(): string {
        return this.assetId;
    }


    setAssetName(assetName: string) {
        this.assetName = assetName;
    }

    setAssetMinCycleTime(assetMinCycleTime: number) {
        this.assetMinCycleTime = assetMinCycleTime;
    }

    setAssetMeanCycleTime(assetMeanCycleTime: number) {
        this.assetMeanCycleTime = assetMeanCycleTime;
    }

    setAssetMxCycleTime(assetMaxCycleTime: number) {
        this.assetMaxCycleTime = assetMaxCycleTime;
    }
}

export class KpiFamily {
    familyID: string;
    familyName: string;
    familyStartDate: string;
    familyEndDate: string;
    familyInZone: string;
    familyNumberOfCycle: number;
    familyMinCycleTime: number;
    familyMedianCycleTime: number;
    familyMeanCycleTime: number;
    familyMaxCycleTime: number;
    familyMovingTime: number;
    familyUnknownTime: number;
    familyStaticTime: number;
    familyInTime: number;
    familyOutTime: number;
    familyAssets: Array<KpiAsset>;
    familyTendanceExpX: Array<number>;
    familyTendanceExpY: Array<number>;


    constructor(familyID: string, familyName: string, familyStartDate: string, familyEndDate: string, familyInZone: string,
        familyNumberOfCycle: number, familyMinCycleTime: number, familyMedianCycleTime: number,
        familyMeanCycleTime: number, familyMaxCycleTime: number, familyMovingTime: number, familyUnknownTime: number, familyStaticTime: number, familyInTime: number, familyOutTime: number, familyTendanceExpX: Array<number>, familyTendanceExpY: Array<number>,
        familyAssets: Array<KpiAsset>) {
        this.familyID = familyID;
        this.familyName = familyName;
        this.familyStartDate = familyStartDate;
        this.familyEndDate = familyEndDate;
        this.familyInZone = familyInZone;
        this.familyNumberOfCycle = familyNumberOfCycle;
        this.familyMinCycleTime = familyMinCycleTime;
        this.familyMedianCycleTime = familyMedianCycleTime;
        this.familyMeanCycleTime = familyMeanCycleTime;
        this.familyMaxCycleTime = familyMaxCycleTime;
        this.familyMovingTime = familyMovingTime;
        this.familyUnknownTime = familyUnknownTime;
        this.familyStaticTime = familyStaticTime;
        this.familyInTime = familyInTime;
        this.familyOutTime = familyOutTime;
        this.familyTendanceExpX = familyTendanceExpX;
        this.familyTendanceExpY = familyTendanceExpY;
        this.familyAssets = familyAssets;
    }

    getFamilyID(): string {
        return this.familyID;
    }
    getFamilyName(): string {
        return this.familyName;
    }
    getFamilyNumberOfCycle(): number {
        return this.familyNumberOfCycle;
    }
    getFamilyMinCycleTime(): number {
        return this.familyMinCycleTime;
    }
    getFamilyMedianCycleTime(): number {
        return this.familyMedianCycleTime;
    }
    getFamilyMeanCycleTime(): number {
        return this.familyMeanCycleTime;
    }
    getFamilyMaxCycleTime(): number {
        return this.familyMaxCycleTime;
    }
    getFamilyMovingTime(): number {
        return this.familyMovingTime;
    }
    getFamilyUnknownTime(): number {
        return this.familyUnknownTime;
    }
    getFamilyStaticTime(): number {
        return this.familyStaticTime;
    }
    getFamilyInTime(): number {
        return this.familyInTime;
    }
    getFamilyOutTime(): number {
        return this.familyOutTime;
    }
    getFamilyAssets(): Array<KpiAsset> {
        return this.familyAssets;
    }

    setFamilyID(familyId: string) {
        this.familyID = familyId;
    }
    setFamilyName(familyName: string) {
        this.familyName = familyName;
    }
    setFamlilyNumberOfCycle(famlilyNumberOfCycle: number) {
        this.familyNumberOfCycle = famlilyNumberOfCycle;
    }
    setFamilyMinCycleTime(familyMinCycleTime: number) {
        this.familyMinCycleTime = familyMinCycleTime;
    }
    setFamilyMedianCycleTime(familyMedianCycleTime: number) {
        this.familyMedianCycleTime = familyMedianCycleTime;
    }
    setFamilyMeanCycleTime(familyMeanCycleTime: number) {
        this.familyMeanCycleTime = familyMeanCycleTime;
    }
    setFamilyMaxCycleTime(familyMaxCycleTime: number) {
        this.familyMaxCycleTime = familyMaxCycleTime;
    }
    setFamilyMovingTime(familyMovingTime: number) {
        this.familyMovingTime = familyMovingTime;
    }
    setFamilyUnknownTime(familyUnknownTime: number) {
        this.familyUnknownTime = familyUnknownTime;
    }
    setFamilyStaticTime(familyStaticTime: number) {
        this.familyStaticTime = familyStaticTime;
    }
    setFamilyInTime(familyInTime: number) {
        this.familyInTime = familyInTime;
    }

    setFamilyOutTime(familyOutTime: number) {
        this.familyOutTime = familyOutTime;
    }
    setFamilyAssets(familyAssets: Array<KpiAsset>) {
        this.familyAssets = familyAssets;
    }
}

export class KpiTimeInZoneAsset {
    sumTimePassedIn: number;
    asset: KpiAsset;

    constructor(sumTimePassedIn: number, asset: KpiAsset) {
        this.sumTimePassedIn = sumTimePassedIn;
        this.asset = asset;
    }

    getSumTimePassedIn(): number {
        return this.sumTimePassedIn;
    }
    getAsset(): KpiAsset {
        return this.asset;
    }

    setSumTimePassedIn(sumTimePassedIn: number) {
        this.sumTimePassedIn = sumTimePassedIn;
    }
    setAsset(asset: KpiAsset) {
        this.asset = asset;
    }
}

export class KpiTimeZone {
    kpiTimeZoneName: string;
    kpiTimeZoneMin: number;
    kpiTimeZoneMean: number;
    kpiTimeZoneMax: number;
    kpiTimeZoneAssets: Array<KpiTimeInZoneAsset>;

    constructor(kpiTimeZoneName: string, kpiTimeZoneMin: number, kpiTimeZoneMean: number, kpiTimeZoneMax: number,
        kpiTimeZoneAssets: Array<KpiTimeInZoneAsset>) {
        this.kpiTimeZoneName = kpiTimeZoneName;
        this.kpiTimeZoneMin = kpiTimeZoneMin;
        this.kpiTimeZoneMean = kpiTimeZoneMean;
        this.kpiTimeZoneMax = kpiTimeZoneMax;
        this.kpiTimeZoneAssets = kpiTimeZoneAssets;
    }

    getKpiTimeZoneName(): string {
        return this.kpiTimeZoneName;
    }
    getKpiTimeZoneMin(): number {
        return this.kpiTimeZoneMin;
    }
    getKpiTimeZoneMean(): number {
        return this.kpiTimeZoneMean;
    }
    getKpiTimeZoneMax(): number {
        return this.kpiTimeZoneMax;
    }
    getkpiTimeZoneAssets(): Array<KpiTimeInZoneAsset> {
        return this.kpiTimeZoneAssets;
    }

    setKpiTimeZoneName(kpiTimeZoneName: string) {
        this.kpiTimeZoneName = kpiTimeZoneName;
    }
    setKpiTimeZoneMin(kpiTimeZoneMin: number) {
        this.kpiTimeZoneMin = kpiTimeZoneMin;
    }
    setKpiTimeZoneMean(kpiTimeZoneMean: number) {
        this.kpiTimeZoneMean = kpiTimeZoneMean;
    }
    setKpiTimeZoneMax(kpiTimeZoneMax: number) {
        this.kpiTimeZoneMax = kpiTimeZoneMax;
    }
    setkpiTimeZoneAssets(kpiTimeZoneAssets: Array<KpiTimeInZoneAsset>) {
        this.kpiTimeZoneAssets = kpiTimeZoneAssets;
    }
}

export class KpiTimeInZone {
    familyName: string;
    kpiTimeZone: Array<KpiTimeZone>;

    constructor(familyName: string, kpiTimeZone: Array<KpiTimeZone>) {
        this.familyName = familyName;
        this.kpiTimeZone = kpiTimeZone;
    }

    getFamilyName(): string {
        return this.familyName;
    }
    getKpiTimeZone(): Array<KpiTimeZone> {
        return this.kpiTimeZone;
    }

    setFamilyName(familyName: string) {
        this.familyName = familyName;
    }
    setKpiTimeZone(kpiTimeZone: Array<KpiTimeZone>) {
        this.kpiTimeZone = kpiTimeZone;
    }
}



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class KpiService {

    private _kpiSelection$ = new BehaviorSubject<KpiDataFormat>(null);
    public kpiSelection$ = this._kpiSelection$.asObservable().pipe(
        shareReplay(1),
    );

    private _kpiFamily$ = new BehaviorSubject<KpiFamily>(
        new KpiFamily('', '', '', '', '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, [], [], [])
    );
    public kpiFamily$ = this._kpiFamily$.asObservable().pipe(
        shareReplay(1),
    );

    private _kpiTimeInZone$ = new BehaviorSubject<KpiTimeInZone>(
        new KpiTimeInZone('', [])
    );
    public kpiTimeInZone$ = this._kpiTimeInZone$.asObservable().pipe(
        shareReplay(1),
    );

    public previousPercentage = 0;

    private _computing$ = new BehaviorSubject<boolean>(false);
    public computing$ = this._computing$.asObservable().pipe(
        shareReplay(1),
    );

    optionDisplayPercentage: false;
    optionDisplayLevel2Zones: false;
    reload = false;
    constructor(
        private http: HttpClient,
        private readonly _translate: TranslateService,
        private readonly _snackbar: SnackbarComponent,
        private readonly _errorTranslationService: ErrorTranslationService
    ) { }

    public setReload(value: boolean) {
        this._computing$.next(value);
        this.reload = value;
    }

    postKpiMain(data: KpiDataFormat): Observable<{ message: string }> {
        return this.http.post<{ message: string }>(
            `${environment.API_ENDPOINT}/kpi`,
            data,
            httpOptions
        );
    }

    hydratationKpiFamily(obj: Record<string, any>): KpiFamily {
        const assets: Array<KpiAsset> = [];
        for (let i = 0; i < obj.assets.length; i++) {
            const cycles: Array<KpiCycle> = [];
            for (let j = 0; j < obj.assets[i].cycles.length; j++) {
                const zones: Array<KpiZone> = [];
                for (let k = 0; k < obj.assets[i].cycles[j].zones.length; k++) {
                    const zonesIn1: Array<KpiZoneIn1> = [];
                    for (let l = 0; l < obj.assets[i].cycles[j].zones[k].zonesIn1.length; l++) {
                        const zoneIn1 = new KpiZoneIn1(obj.assets[i].cycles[j].zones[k].zonesIn1[l].name, obj.assets[i].cycles[j].zones[k].zonesIn1[l].level,
                            obj.assets[i].cycles[j].zones[k].zonesIn1[l].order, obj.assets[i].cycles[j].zones[k].zonesIn1[l].timePassedIn, null);
                        zonesIn1.push(zoneIn1);
                    }
                    const zone = new KpiZone(obj.assets[i].cycles[j].zones[k].name, obj.assets[i].cycles[j].zones[k].level, obj.assets[i].cycles[j].zones[k].order,
                        obj.assets[i].cycles[j].zones[k].timePassedIn, zonesIn1);
                    zones.push(zone);
                }
                const cycle = new KpiCycle(obj.assets[i].cycles[j].startDate, obj.assets[i].cycles[j].cycleOrder, obj.assets[i].cycles[j].cycleTime, obj.assets[i].cycles[j].movingTime, obj.assets[i].cycles[j].unknownTime,
                    obj.assets[i].cycles[j].staticTime, obj.assets[i].cycles[j].inTime, obj.assets[i].cycles[j].outTime, zones);
                cycles.push(cycle);
            }
            const asset = new KpiAsset(obj.assets[i].id, obj.assets[i].name, obj.assets[i].numberOfCycle, obj.assets[i].minCycleTime,
                obj.assets[i].medianCycleTime, obj.assets[i].meanCycleTime, obj.assets[i].maxCycleTime, obj.assets[i].movingTime, obj.assets[i].unknownTime,
                obj.assets[i].staticTime, obj.assets[i].inTime, obj.assets[i].outTime, cycles);
            assets.push(asset);
        }
        return new KpiFamily(obj.id, obj.name, obj.startDate, obj.endDate, obj.inZoneName, obj.numberOfCycle, obj.minCycleTime, obj.medianCycleTime, obj.meanCycleTime,
            obj.maxCycleTime, obj.movingTime, obj.unknownTime, obj.staticTime, obj.inTime, obj.outTime, obj.tendanceListXexpo, obj.tendanceListYexpo, assets);

    }

    updatekpiTimeInZoneDatePerFamilly(kpifamily: KpiFamily): KpiTimeInZone {
        // récupération d'un mape <zone, list d'asset passant par cette zone>
        const mapZoneAssets = new Map<string, Array<KpiAsset>>();
        let listTmpAssets = new Array<KpiAsset>();
        for (let i = 0; i < kpifamily.getFamilyAssets().length; i++) {
            for (let j = 0; j < kpifamily.getFamilyAssets()[i].getAssetCycles().length; j++) {
                const listTmpAssetsIds = [];
                for (let k = 0; k < kpifamily.getFamilyAssets()[i].getAssetCycles()[j].getCycleZones().length; k++) {
                    // si ma zone existe déjà, je doit mettre à jour ma liste d'assets
                    if (mapZoneAssets.has(kpifamily.getFamilyAssets()[i].getAssetCycles()[j].getCycleZones()[k].getZoneName())) {
                        // je récupère la liste d'asset de ma zone
                        listTmpAssets = mapZoneAssets.get(kpifamily.getFamilyAssets()[i].getAssetCycles()[j].getCycleZones()[k].getZoneName());
                        // si mon asset n'existe pas je l'ajoute à ma liste et met à jour ma map <zone, listAsset>, sinon, je ne fais rien
                        for (let l = 0; l < listTmpAssets.length; l++) {
                            listTmpAssetsIds.push(listTmpAssets[l].getAssetId());
                        }
                        if (!listTmpAssetsIds.includes(kpifamily.getFamilyAssets()[i].getAssetId())) {
                            listTmpAssets.push(kpifamily.getFamilyAssets()[i]);
                            mapZoneAssets.delete(kpifamily.getFamilyAssets()[i].getAssetCycles()[j].getCycleZones()[k].getZoneName());
                            mapZoneAssets.set(kpifamily.getFamilyAssets()[i].getAssetCycles()[j].getCycleZones()[k].getZoneName(), listTmpAssets);
                        }
                    } else {
                        const newListAssets = new Array<KpiAsset>();
                        newListAssets.push(kpifamily.getFamilyAssets()[i]);
                        mapZoneAssets.set(kpifamily.getFamilyAssets()[i].getAssetCycles()[j].getCycleZones()[k].getZoneName(), newListAssets);
                    }
                }
            }
        }

        // récupération KpiTimeZone
        const listOfkpiTimeZone = new Array<KpiTimeZone>();
        // création des KpiTimeInZoneAsset
        // for (let m = 0; m < mapZoneAssets.size; m++) {
        const iterMap = mapZoneAssets.keys();
        const size = mapZoneAssets.size;
        for (let a = 0; a < size; a++) {
            const kpiTimeInZoneAssets = new Array<KpiTimeInZoneAsset>();
            const key = iterMap.next().value;
            const value = mapZoneAssets.get(key);
            for (let i = 0; i < value.length; i++) {
                let tmpSumTimePassedIn = 0;
                for (let j = 0; j < value[i].getAssetCycles().length; j++) {
                    for (let k = 0; k < value[i].getAssetCycles()[j].getCycleZones().length; k++) {
                        if (key === value[i].getAssetCycles()[j].getCycleZones()[k].getZoneName()) {
                            tmpSumTimePassedIn += value[i].getAssetCycles()[j].getCycleZones()[k].getTimePassedIn();
                        }
                    }
                }
                kpiTimeInZoneAssets.push(new KpiTimeInZoneAsset(tmpSumTimePassedIn, value[i]));
            }


            // calcul des Min, Mean et Max
            const listOfValueForMedMaxMeanCalcul = [];
            let tmpTimePassedInMean = 0;
            let tmpTimePassedInMax = 0;
            let tmpTimePassedInMin = 0;
            for (let j = 0; j < kpiTimeInZoneAssets.length; j++) {
                listOfValueForMedMaxMeanCalcul.push(kpiTimeInZoneAssets[j].getSumTimePassedIn());
            }
            tmpTimePassedInMin = min(listOfValueForMedMaxMeanCalcul);
            tmpTimePassedInMean = mean(listOfValueForMedMaxMeanCalcul);
            tmpTimePassedInMax = max(listOfValueForMedMaxMeanCalcul);
            listOfkpiTimeZone.push(new KpiTimeZone(key, tmpTimePassedInMin, tmpTimePassedInMean, tmpTimePassedInMax, kpiTimeInZoneAssets));
        }
        return new KpiTimeInZone(kpifamily.getFamilyName(), listOfkpiTimeZone);
    }


    checkForAchievement(): Observable<KpiAchievement> {
        this.setReload(true);
        return this.http.get<KpiAchievement>(`${environment.API_ENDPOINT}/kpi`).pipe(
            catchError(error => {
                this.setReload(false);
                this._errorTranslationService.handleError(error, this._translate.instant('NO_REQUEST_TO_RENDER'));
                return EMPTY;
            }),
            switchMap(data => {
                switch (data.status) {
                    case 'WIP':
                        // Check previous percentage should be less than current percentage to show snackbar
                        if(data.percentage > this.previousPercentage) {
                        this._snackbar.open(
                            this._translate.instant('LOADED_AT_PERCENT', { number: data.percentage }),
                            'green-snackbar',
                            5000
                        );
                        this.previousPercentage = data.percentage;
                        }
                        return this.checkForAchievement();
                    case 'FAILED':
                        // Your result is empty, please enter better zones or larger dates`;
                        this._snackbar.open(this._translate.instant('KPI_NO_RESULT'), 'red-snackbar', 5000);
                        this.setReload(false);
                        return EMPTY;
                    case 'NONE':
                        this.setReload(false);
                        return EMPTY;
                    case 'DONE':
                        this.setReload(false);
                        this.previousPercentage = 0
                        this._handleKpiComputingDone(data.jsonResult);
                        return of(data);
                }
            })
        );
    }

    private _handleKpiComputingDone(jsonResult: KpiAchievement['jsonResult']) {
        const json = JSON.parse(jsonResult);
        const kpiFamily = this.hydratationKpiFamily(json);
        this._kpiFamily$.next(kpiFamily);
        this._kpiTimeInZone$.next(this.updatekpiTimeInZoneDatePerFamilly(kpiFamily));
        this._kpiSelection$.next(json.computingSelection);
        if (kpiFamily.familyNumberOfCycle === 0) {
            this._snackbar.open(this._translate.instant('EMPTY_KPI'), 'red-snackbar', 5000);
        }
    }
}
