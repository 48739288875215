import { ComponentPortal, ComponentType, DomPortalOutlet } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import Graphic from '@arcgis/core/Graphic';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import TileLayer from '@arcgis/core/layers/TileLayer';
import ActionButton from '@arcgis/core/support/actions/ActionButton';
import ActionToggle from '@arcgis/core/support/actions/ActionToggle';
import MapView from '@arcgis/core/views/MapView';
import { Observable } from 'rxjs';
import { ErsiFeaturesLoadingStatus } from 'src/app/components/esri-map/widget/features-loading/features-loading.component';

export class EsriBase {
    protected renderer: Renderer2;
    protected featuresLoadingStatus: ErsiFeaturesLoadingStatus;
    private _objectIdGraphicsKey: Record<string, number> = {};

    constructor(
        protected rendererFactory: RendererFactory2,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected appRef: ApplicationRef,
        protected injector: Injector,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    /**
     * Creator of the custom content of the popup with an Angular component
     * @param component The component to render in the popup
     * @returns The element to add as content of the popup
     */
    protected createElementWithAngularComponent<T>(map: MapView, component: ComponentType<T>, injector?: Injector, isPopup = true): HTMLDivElement {
        try {
            const el = this.renderer.createElement('div') as HTMLDivElement;
            const outlet = new DomPortalOutlet(
                el,
                this.componentFactoryResolver,
                this.appRef,
                this.injector,
            );
            const portal = new ComponentPortal<T>(component, null, injector);
            outlet.attach(portal);
            if (isPopup) {
                this._disposePopupOutletWhenClosedOrReplaced(map, outlet);
            } else {
                this._disposeOutletWhenMapDestroyed(map, outlet);
            }
            return el;
        } catch (error) {
            // console.error('ersi-map', 'map', error.message, error);
        }
    }

    /**
     * Dispose the outlet in the popup when the popup is closed
     * @param outlet The outlet to dispose
     */
    private _disposePopupOutletWhenClosedOrReplaced(map: MapView, outlet: DomPortalOutlet): void {
        reactiveUtils.whenOnce(() => map.popup.visible).then(
            () => {
                const handle = reactiveUtils.watch(
                    () => map.popup.content,
                    () => {
                        outlet.dispose();
                        handle.remove();
                    }
                );
            }
        );
        reactiveUtils.whenOnce(() => !map.popup.visible).then(
            () => {
                outlet.dispose();
            }
        );
    }

    private _disposeOutletWhenMapDestroyed(map: MapView, outlet: DomPortalOutlet): void {
        reactiveUtils.whenOnce(() => map.destroyed).then(
            () => {
                outlet.dispose();
            }
        );
    }

    /**
     * Handle the change of selection of feature
     * @param callback The callback to do when the feature selection changes
     */
    protected handlePopupFeatureSelectionChange(map: MapView, layer: FeatureLayer, callback: (newValue: Graphic, oldValue: Graphic) => void) {
        reactiveUtils.watch(
            () => map.popup.selectedFeature,
            (newValue, ...args) => {
                if (!newValue) {
                    // Do not execute the callback
                    return;
                }
                if (newValue.layer === layer) {
                    callback(newValue, ...args);
                }
            }
        );
    }

    protected handlePopupClose(map: MapView, callback: () => void) {
        reactiveUtils.when(
            () => !map.popup.visible,
            () => callback()
        );
    }

    protected handlePopupActionClick<T extends ActionButton | ActionToggle>(map: MapView, actionId: string, callback: (action: T) => void) {
        reactiveUtils.on(
            () => map?.popup,
            'trigger-action',
            (event: { action: T; }) => {
                if (event.action.id === actionId) {
                    callback(event.action);
                }
            }
        );
    }

    /**
     * Function that upload as many features as possible to a
     * client-side FeatureLayer without blocking the UI thread.
     *
     * @param layer - The layer to upload the features to
     * @param iterator - The iterator to consume features
     * @param batchTime - The amount of time during which the iterator can be consumed. By default 4ms
     */
    protected async changeFeatures(
        layers: FeatureLayer[],
        iterators: {
            add: Generator<Graphic>;
            update: Generator<Graphic>;
            delete: Generator<Graphic>;
        },
        keyIdentificationFunction: (g: Graphic) => string,
        batchTime = 4,
    ) {
        this.featuresLoadingStatus.loading = true;
        let added = iterators.add.next();
        let updated = iterators.update.next();
        let deleted = iterators.delete.next();
        while (!added.done || !updated.done || !deleted.done) {
            const start = performance.now();
            const addFeatures: Graphic[] = [];
            const updateFeatures: (Graphic & { objectId: number })[] = [];
            const deleteFeatures: { objectId: number }[] = [];

            // consume for batchTime milliseconds.
            while (performance.now() - start < batchTime && !added.done) {
                addFeatures.push(added.value);
                added = iterators.add.next();
            }
            // consume for batchTime milliseconds.
            while (performance.now() - start < batchTime && !updated.done) {
                updateFeatures.push({
                    objectId: this._objectIdGraphicsKey[keyIdentificationFunction(updated.value)],
                    ...updated.value,
                });
                updated = iterators.update.next();
            }
            // consume for batchTime milliseconds.
            while (performance.now() - start < batchTime && !deleted.done) {
                deleteFeatures.push({
                    objectId: this._objectIdGraphicsKey[keyIdentificationFunction(deleted.value)],
                });
                deleted = iterators.delete.next();
            }

            if (addFeatures.length || updateFeatures.length || deleteFeatures.length) {
                const results = await Promise.all(layers.map(
                    layer => layer.applyEdits({
                        addFeatures,
                        updateFeatures,
                        deleteFeatures,
                    })
                ));
                results.forEach(
                    (result) => {
                        result.addFeatureResults.forEach(
                            (addResult, index) => {
                                if (addResult.objectId) {
                                    this._objectIdGraphicsKey[keyIdentificationFunction(addFeatures[index])] = addResult.objectId;
                                }
                            }
                        );
                        result.deleteFeatureResults.forEach(
                            (deleteResult) => {
                                if (deleteResult.objectId) {
                                    const key = Object.entries(this._objectIdGraphicsKey).find(
                                        ([_, v]) => v === deleteResult.objectId
                                    )?.[0];
                                    delete this._objectIdGraphicsKey[key];
                                }
                            }
                        );
                    }
                );
            }
        }
        this.featuresLoadingStatus.loading = false;
    }

    protected async zoomToFeaturesAndDisplayPopup(map: MapView, features: Graphic[], layer: FeatureLayer) {
        const zoom = this.getMaxZoom(map);
        await map.goTo({
            target: features,
            zoom,
        });
        map.openPopup({
            features,
            updateLocationEnabled: true,
            shouldFocus: true,
        });
    }

    /**
     * Get a scale corresponding to the zoom level
     */
    protected getScaleForZoom(map: MapView, zoom: number): Observable<number> {
        return new Observable<number>(
            observer => {
                reactiveUtils.when(
                    () => map.basemapView?.baseLayerViews?.length,
                    () => {
                        const baseLayer = map.basemapView.baseLayerViews.getItemAt(0);
                        const layer = baseLayer?.layer as TileLayer;
                        const tileInfo = layer?.tileInfo;
                        const maxZoom = Math.max(...tileInfo.lods.map(lod => lod.level));
                        zoom = zoom > maxZoom ? maxZoom : zoom;
                        observer.next(tileInfo.lods.find(lod => lod.level === zoom)?.scale);
                    }
                );
                reactiveUtils.whenOnce(
                    () => map.destroyed,
                ).then(() => {
                    observer.complete();
                });
            }
        );
    }

    /**
     * Get the max zoom.
     * Remember that the max zoom is depending on the basemap.
     * If the basemap changes, the previous returned value might not be true anymore.
     */
    protected getMaxZoom(map: MapView): number {
        if (map.basemapView?.baseLayerViews?.length) {
            const baseLayer = map.basemapView.baseLayerViews.getItemAt(0);
            const layer = baseLayer?.layer as TileLayer;
            const tileInfo = layer?.tileInfo;
            let maxZoom = Math.max(...tileInfo.lods.map(lod => lod.level));
            return --maxZoom;
        }
        return Infinity;
    }

    /**
     * Get the max zoom depending on the basemap.
     * Returns an observable with the max zoom which can change depending on the basemap.
     */
    protected getMaxZoom$(map: MapView): Observable<number> {
        return new Observable<number>(
            observer => {
                reactiveUtils.when(
                    () => map.basemapView?.baseLayerViews?.length,
                    () => observer.next(this.getMaxZoom(map))
                );
                reactiveUtils.whenOnce(
                    () => map.destroyed,
                ).then(() => {
                    observer.complete();
                });
            }
        );
    }
}
