<div mat-dialog-title>
    <span>{{ (!data.multiple || ((data.limit | typeOf) === 'number' && data.limit <= 1) ? 'ADD_USER' : 'ADD_USERS') | translate }}</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<form [formGroup]="userForm"
        (ngSubmit)="onFormSubmit()">
    <mat-dialog-content class="form-content">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NAME' | translate }}</mat-label>
        <mat-select formControlName="users"
                    [multiple]="this.data.multiple"
                    #multiselect
                    matTooltipClass="multiline-tooltip"
                    [matTooltip]="(selectedUsers.selected | extractPropertyPipe: data.displayField | join:', ')">
          <mat-select-trigger *ngIf="selectedUsers.selected as selection">
            {{ selection[0]?.[data.displayField] || '' }}
            <span *ngIf="(selection.length || 0) > 1">
              (+{{ selection.length - 1 }}&nbsp;{{ (selection.length > 2 ? 'OTHERS' : 'OTHER') | translate | lowercase }})
            </span>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                   [formControl]="searchControl"
                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of users$ | async"
                      [value]="user.id">
            {{ user.userId }}
            <span *ngIf="user.email">&nbsp;|&nbsp;<small>{{ user.email }}</small></span>
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="usersLoading === true">
            <mat-progress-spinner mode="indeterminate"
                                  diameter="12"
                                  strokeWidth="2"></mat-progress-spinner>
            {{ "LOADING_DATA" | translate }}
        </mat-hint>
        <mat-error *ngIf="userForm.controls.users.errors?.required">
            <fa-icon icon="circle-exclamation"></fa-icon>
            {{ "REQUIRED_SELECTION" | translate }}
        </mat-error>
        <mat-error *ngIf="userForm.controls.users.errors?.invalid">
            <fa-icon icon="circle-exclamation"></fa-icon>
            {{ "INVALID_SELECTION" | translate }}
        </mat-error>
        <mat-error *ngIf="userForm.controls.users.errors?.overLimit">
            <fa-icon icon="circle-exclamation"></fa-icon>
            {{ "ERROR_SELECTION_MAX" | translate:{max: this.data.limit} }}
        </mat-error>
      </mat-form-field>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <button mat-dialog-close
                mat-stroked-button
                type="button"
                color="primary">
            {{"CANCEL" | translate}}
        </button>
        <button mat-flat-button
                color="primary"
                type="submit"
                [disabled]="userForm.invalid">
            {{"DIALOG_VALIDATE_OPTION" | translate}}
        </button>
    </div>

  </form>
