import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Asset } from '../models/asset';

@Injectable({
    providedIn: 'root'
})
export class MapActionsService {

    constructor(
        private _router: Router,
    ) { }

    public goToPlanningFromAsset(asset: Asset) {
        const url = this._router.serializeUrl(
            this._router.createUrlTree(['/planning'], {
                queryParams: { transportFamilyName: asset.family.id }
            })
        );
        window.open(url, '_blank');
    }

}
