import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule as FaModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
    faAngleDown,
    faAngleUp,
    faArrowAltCircleLeft,
    faBars,
    faBatteryEmpty,
    faBatteryFull,
    faBatteryHalf,
    faBatteryQuarter,
    faBatteryThreeQuarters,
    faBell,
    faBook,
    faBoxOpen,
    faCalendar,
    faCaretDown,
    faCaravan,
    faCaretUp,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faClock,
    faCog,
    faCrosshairs,
    faDownload,
    faDrawPolygon,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faFileImage,
    faFilm,
    faFilter,
    faGhost,
    faGift,
    faGraduationCap,
    faHammer,
    faHistory,
    faHourglassHalf,
    faIdCardClip,
    faInfoCircle,
    faLink,
    faLocationDot,
    faLockOpen,
    faPaperPlane,
    faPencilAlt,
    faPlus,
    faPlusCircle,
    faQuestionCircle,
    faRedo,
    faRightFromBracket,
    faRotateLeft,
    faScroll,
    faSlash,
    faSnowman,
    faSortDown,
    faSortUp,
    faSpider,
    faSyncAlt,
    faTableList,
    faTimes,
    faTimesCircle,
    faTrash,
    faTruckRampBox,
    faUndo,
    faUnlink,
    faUpload,
    faUser,
    faUsersSlash,
    faVolumeOff,
    faVolumeUp,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [
    FaModule,
  ],
})
export class FontAwesomeModule {
    constructor(
        private readonly _fa: FaIconLibrary
    ) {
        // this._fa.addIconPacks(fas, far);
        /**
         * Try to keep the icon names in alphabetical order to ease finding
         */
        this._fa.addIcons(
            faAngleDown,
            faAngleUp,
            faArrowAltCircleLeft,
            faBars,
            faBatteryEmpty,
            faBatteryFull,
            faBatteryHalf,
            faBatteryQuarter,
            faBatteryThreeQuarters,
            faBell,
            faBook,
            faBoxOpen,
            faCalendar,
            faCaretDown,
            faCaravan,
            faCaretUp,
            faCheck,
            faChevronLeft,
            faChevronRight,
            faClock,
            faCog,
            faCrosshairs,
            faDownload,
            faDrawPolygon,
            faEdit,
            faEllipsisH,
            faEllipsisV,
            faEnvelope,
            faExclamationCircle,
            faExclamationTriangle,
            faFileImage,
            faFilm,
            faFilter,
            faGhost,
            faGift,
            faGraduationCap,
            faHammer,
            faHistory,
            faHourglassHalf,
            faIdCardClip,
            faInfoCircle,
            faLink,
            faLocationDot,
            faLockOpen,
            faPaperPlane,
            faPencilAlt,
            faPlus,
            faPlusCircle,
            faQuestionCircle,
            faRedo,
            faRightFromBracket,
            faRotateLeft,
            faScroll,
            faScroll,
            faSlash,
            faSnowman,
            faSortDown,
            faSortUp,
            faSpider,
            faSyncAlt,
            faTableList,
            faTimes,
            faTimesCircle,
            faTrash,
            faTruckRampBox,
            faUndo,
            faUnlink,
            faUpload,
            faUser,
            faUsersSlash,
            faVolumeOff,
            faVolumeUp,
            faWrench,
        );
    }
}
