import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

export class ErsiFeaturesLoadingStatus {

    private _loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _progress$: Subject<number> = new Subject();

    public set loading(value: boolean) {
        this._loading$.next(value);
    }

    public set progress(value: number) {
        this._progress$.next(value);
    }

    public get loading$(): Observable<boolean> {
        return this._loading$.asObservable();
    }

    public get progress$(): Observable<number> {
        return this._progress$.asObservable();
    }

}

@Component({
    selector: 'app-features-loading',
    templateUrl: './features-loading.component.html',
    styleUrls: ['./features-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EsriFeaturesLoadingComponent {

    constructor(
        @Inject(ErsiFeaturesLoadingStatus) public loadingStatus: ErsiFeaturesLoadingStatus,
        private _cd: ChangeDetectorRef,
    ) {
        this.loadingStatus.loading$.pipe(
            distinctUntilChanged(),
            tap(() => this._cd.markForCheck()),
        );
    }

}
