<div class="way-table--main-container">

    <!-- Header -->
    <div class="way-component--header">
        <span class="way-component--header-title">Dev tools</span>
        <div class="way-component--header-buttons">
            <button mat-flat-button color="primary" (click)="generateEvent()">
                <fa-icon icon="plus-circle"></fa-icon>
                {{ 'GENERATE_EVENT' | translate }}
            </button>
            <button mat-flat-button color="primary" (click)="generatePlanning()">
                <fa-icon icon="plus-circle"></fa-icon>
                Generate Planning
            </button>
            <button mat-flat-button color="primary" (click)="generatePlanningLine()">
                <fa-icon icon="plus-circle"></fa-icon>
                Create A planned Line
            </button>
            <button mat-flat-button color="primary" (click)="openPopupForTestAPI()">
                <fa-icon icon="plus-circle"></fa-icon>
                API Tester
            </button>
        </div>
    </div>
    <div>Tool developed for the development team.</div>
    <div>Only use this feature if you are sure of what you are doing.</div>
    <div>
        <button class="bottom-button" mat-button (click)="chaos()">
            Bring chaos!
        </button>
    </div>
    </div>
