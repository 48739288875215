<header>
    <!-- header -->
    <app-layout-header></app-layout-header>
</header>

<main>
    <!-- Banners to display below the header -->
    <app-banner></app-banner>

    <!-- routes will be rendered here -->
    <router-outlet></router-outlet>
</main>

<ng-container *ngIf="showFilterContext$ | async">
    <div class="context-fab">
        <app-filter-context-menu></app-filter-context-menu>
    </div>
</ng-container>

<!-- Loader -->
<div [appHidden]="!loader"
     class="overlay">
    <mat-progress-spinner class="spinner"
                          mode="indeterminate"
                          value="50">
    </mat-progress-spinner>
</div>
