<div mat-dialog-title>
    <span>API Test</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<div class="main-container">
    <mat-dialog-content>
        <form [formGroup]="apiTestForm">
            <mat-form-field>
                <mat-label>{{ "URL_TEXT"| translate }}</mat-label>
                <input matInput
                       formControlName="url"
                       name="url">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ "AUTHORZATION_TOKEN" | translate }}</mat-label>
                <input matInput
                       [type]="hide ? 'password' : 'text'"
                       formControlName="token"
                       name="token">
                <button type="button"
                        mat-icon-button
                        matSuffix
                        (click)="hide = !hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ "HTTP_METHOD" | translate }}</mat-label>
                <mat-select formControlName="method"
                            name="method">
                    <mat-option *ngFor="let method of METHODS"
                                [value]="method.value">
                        {{ method.key }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <fieldset
                      *ngIf="apiTestForm.value.method !== HttpMethod.GET && apiTestForm.value.method !== HttpMethod.OPTIONS">
                <legend>{{ "BODY_JSON_FORMAT" | translate }}</legend>
                <button mat-raised-button
                        type="button"
                        (click)="formatePayload()"
                        class="action">
                    {{ "FORMATE" | translate }}
                </button>
                <textarea class="json-body-font"
                          matInput
                          id="description"
                          type="text"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="5"
                          cdkAutosizeMaxRows="20"
                          formControlName="payload"
                          name="payload"
                          [placeholder]="'BODY_JSON_FORMAT' | translate"></textarea>
            </fieldset>

        </form>

        <form [formGroup]="apiTestResult">

            <div class="api-response"
                 *ngIf="apiTestResult.value.apiResponse as response">
                <fieldset>
                    <legend class="{{ response.class }}">
                        {{ "STATUS_CODE" | translate }}: {{ response.status }}</legend>
                    <textarea class="json-body-font"
                              readonly
                              matInput
                              id="status"
                              name="status"
                              type="text"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="20"
                              formControlName="body"
                              name="body"
                              [placeholder]="'API_RESPONSE' | translate"></textarea>
                </fieldset>
            </div>

        </form>

    </mat-dialog-content>

    <div mat-dialog-actions>
        <button mat-dialog-close
                mat-stroked-button
                color="primary">
            {{ "CANCEL" | translate }}
        </button>
        <button type="button"
                mat-flat-button
                color="primary"
                [disabled]="!apiTestForm.valid || isLoading"
                (click)="sendRequest()">
            {{ "SEND_REQUEST" | translate }}
            <mat-progress-spinner *ngIf="isLoading"
                                  color="primary"
                                  mode="indeterminate"
                                  diameter="16">
            </mat-progress-spinner>
        </button>
    </div>

</div>
