import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
    createFamily,
    fetchAllFamilies,
    mappingAllFamiliesParentFamilies,
    successFetchAllFamilies,
    successCreateFamilies,
    mappingSomeFamiliesParentFamilies,
    updateFamily,
    successUpdateFamilies,
    deleteFamily,
    successDeleteFamilies,
    errorDeleteFamilies,
    errorUpdateFamilies,
    errorCreateFamilies,
    errorFetchAllFamilies,
} from '../actions/family.action';
import { Store } from '@ngrx/store';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { FamilyService } from 'src/app/services/family.service';
import { Family } from 'src/app/models/family';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { selectParentFamilyById$, selectParentFamilyState$ } from '../selectors/parent-family.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { selectAssetsByFamiliesIds } from '../selectors/asset.selectors';
import { successUpdateAssets } from '../actions/asset.actions';
import { deleteFamilyFromUserFilter } from '../actions/filter-context.action';
import { contextIsValid$, selectUserSelectedContext$ } from '../selectors/filter-context.selectors';
import { addFamilyProfile } from '../actions/profile.action';
import { CacheService } from 'src/app/services/cache.service';
import { noop } from '../actions/_shared.action';
import { ObjectStore } from 'src/app/models/cache';
import { ActionTypes as UserRightActionTypes, SuccessDeleteMyRights } from '../actions/user-right.action';
import { selectFamiliesByParentFamilyIds$ } from '../selectors/family.selectors';
import { CHUNK_SIZE, ProtectedSignURLResponse } from 'src/app/models/picture';
import { AssetService } from 'src/app/services/asset.service';
import { RoleLevelEnum } from 'src/app/models/user-right';
@Injectable()
export class FamilyEffects {

    fetchAllFamilies$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(fetchAllFamilies),
            switchMap(() => this._cacheService.retrieveCacheStatus$()),
            map(entry => entry.families?.lastRefresh),
            switchMap(lastRefresh => this._familyService.getFamilies(lastRefresh)),
            switchMap(families => combineLatest([
                this._cacheService.deleteData$(ObjectStore.Families, ...(families.deletedData ?? []).map(({ id }) => id)),
                this._cacheService.updateData$(ObjectStore.Families, ...(families.updatedData ?? [])),
                this._cacheService.createData$(ObjectStore.Families, ...(families.createdData?? [])),
            ])),
            switchMap(() => this._cacheService.getData$(ObjectStore.Families)),
            map(families => successFetchAllFamilies({ payload: families })),
            catchError(() => of(errorFetchAllFamilies())),
        );
    });

    public successFetchAllFamilies$ = createEffect(() => { return this._actions$.pipe(
      ofType(successFetchAllFamilies),
      switchMap((action) => this.store.select(selectParentFamilyState$).pipe(
        filter(state => state.loaded),
        take(1),
        map((state) => mappingAllFamiliesParentFamilies({
          families: action.payload,
          parentFamilies: state.data.parentFamilies
        }))
      ))
    ) });

  public createFamily$ = createEffect(() => { return this._actions$.pipe(
      ofType(createFamily),
      switchMap((action) =>
        this._familyService.createFamily(action.family, action.family.parentFamilyId).pipe(
          switchMap((newFamily) => {
            const newFamilyFormatProfile = {
              id: newFamily.id,
              level: RoleLevelEnum.manager,
              parent: action.family.parentFamilyId,
            };
            this.store.dispatch(addFamilyProfile({ profileFamily: newFamilyFormatProfile }));

            // Upload picture if exists
            if (action.fileDetails) {
              const totalChunks = Math.ceil(action.fileDetails.size / CHUNK_SIZE);
              return this._familyService.createPictureFamily(action.fileDetails.name, action.fileDetails.type, newFamily.id).pipe(
                switchMap((res) => {
                  const body = {
                    fileId: res.fileId,
                    fileKey: res.fileKey,
                    parts: totalChunks,
                  };
                  return this._familyService.generateSignedURLForFamilyPicture(body, newFamily.id).pipe(
                    switchMap((signedUrlRes: ProtectedSignURLResponse[]) => {
                      const chunksURL = signedUrlRes.slice(0, totalChunks).map((item) => item.signedUrl);
                      return this._assetService.uploadMultipartFile(action.fileDetails, chunksURL).pipe(
                          switchMap((uploadPartsArray) =>
                            this._familyService
                              .finalizeFamilyPictureUpload(
                                res.fileId,
                                res.fileKey,
                                uploadPartsArray,
                                newFamily.id
                              ).pipe(
                                map((finalizeRes) => {
                                  const updatedFamily = {
                                    ...newFamily,
                                    picture: finalizeRes.picture,
                                  };
                                  this.store.dispatch(successUpdateFamilies({payload: [updatedFamily]}));
                                  this.effectSubject.next('success');
                                  const text = this._translate.instant('BANNER_SUCCESS_CREATE',{ item: newFamily.name });
                                  this._snackBar.open(text, 'green-snackbar', 5000);
                                  return successCreateFamilies({family: updatedFamily});
                                }),
                                catchError((error) => {
                                  this._errorTranslationService.handleError(error,'BANNER_FAIL_INTERNAL_CREATE');
                                  this.effectSubject.next('error');
                                  return of(errorCreateFamilies());
                                })
                              )
                          )
                        );
                    })
                  );
                }),
                catchError((error) => {
                  this._errorTranslationService.handleError(error,'BANNER_FAIL_INTERNAL_CREATE');
                  this.effectSubject.next('error');
                  return of(errorCreateFamilies());
                })
              );
            } else {
              // No file to upload, proceed with creating the family only
              this.effectSubject.next('success');
              return of(successCreateFamilies({ family: newFamily }));
            }
          }),
          catchError((error) => {
            this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
            this.effectSubject.next('error');
            return of(errorCreateFamilies());
          })
        )
      )
    )
  }
  );

    public successCreateFamilies$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(successCreateFamilies),
            switchMap(({ family }) => combineLatest([
                this.store.select(contextIsValid$),
                this.store.select(selectUserSelectedContext$),
                this.store.select(selectParentFamilyById$(family.parentFamilyId)),
            ]).pipe(
                take(1),
                filter(([ valid ]) => valid),
                map(([, context, parentFamily]) => {
                    // If families are set, do nothing, but display message
                    let text = this._translate.instant('BANNER_SUCCESS_CREATE', { item: family.name });
                    if (context?.families?.length) {
                        text += `\n${this._translate.instant('CONTEXT_FILTERED_BY__UPDATE_FOR_VALUE', {
                            type: this._translate.instant('ASSET_FAMILY')?.toLowerCase(),
                        })}`;
                        this._snackBar.open(text, 'green-snackbar', 5000);
                        return noop();
                    }
                    this._snackBar.open(text, 'green-snackbar', 5000);
                    // User is below asset limit, or has not filtered by family => add family
                    return mappingSomeFamiliesParentFamilies({ families: [family], parentFamilies: { [family.parentFamilyId]: parentFamily } });
                }),
            )),
        );
    });

    public updateProfileOnCreation$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(successCreateFamilies),
            switchMap(({ family }) => combineLatest([
                this.store.select(contextIsValid$),
                this.store.select(selectUserSelectedContext$),
            ]).pipe(
                take(1),
                filter(([ valid ]) => valid),
                map(([, context]) => {
                    if (!context?.families?.length) {
                        return addFamilyProfile({ profileFamily: {
                            id: family.id,
                            level: 'manager',
                            parent: family.parentFamilyId,
                        } });
                    }
                }),
            )),
        );
    });

    public updateFamily$ = createEffect(() => { return this._actions$.pipe(
        ofType(updateFamily),
        switchMap((action) => {
            return this._familyService.updateFamily(action.family, action.family.id).pipe(
              concatMap((familyUpdated: Family) => {
                const text: string = this._translate.instant('BANNER_SUCCESS_EDIT', { value: action.family.name });
                this._snackBar.open(text, 'green-snackbar', 5000);
                this.effectSubject.next('success');
                return of(successUpdateFamilies({
                  payload: [familyUpdated]
                }));
              }),
              catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_EDIT');
                this.effectSubject.next('error');
                return of(errorUpdateFamilies());
              })
            );
        })
    ) });

    public successUpdateFamilies$ = createEffect(() => { return this._actions$.pipe(
      ofType(successUpdateFamilies),
      switchMap((action) => this.store.select(selectParentFamilyState$).pipe(
        filter(state => state.loaded),
        take(1),
        tap(state => this.store.dispatch(mappingSomeFamiliesParentFamilies({
          families: action.payload,
          parentFamilies: state.data.parentFamilies
        }))),
        // update children
        switchMap(() => this.store.select(selectAssetsByFamiliesIds(action.payload.map(family => family.id))).pipe(take(1))),
        map((assets) => successUpdateAssets({ payload: assets }))
      ))
    ) });

    public deleteFamily$ = createEffect(() => { return this._actions$.pipe(
        ofType(deleteFamily),
        switchMap((action) => this._familyService.deleteFamily(action.payload).pipe(map(() => action))),
        tap(() => this.effectSubject.next('success')),
        map((action) => {
            this.store.dispatch(deleteFamilyFromUserFilter({ familyId: action.payload}));
            return successDeleteFamilies({ payload: [{ id: action.payload }] });
        }),
        catchError(error => {
            this.effectSubject.next(error);
            return of(errorDeleteFamilies());
        })
    ) });

    public removeFamiliesBasedOnRemoveRights$ = createEffect(() => {
        return this._actions$.pipe(
            ofType<SuccessDeleteMyRights>(UserRightActionTypes.SUCCESS_DELETE_MY_RIGHT),
            switchMap(({ payload }) => {
                if (payload.removeRight.isParentFamily) {
                    return this.store.select(selectFamiliesByParentFamilyIds$([payload.removeRight.deleteMyRight.id])).pipe(
                        take(1),
                        switchMap(families => {
                            const deleteFamilyIds = families.map(family => family.id);

                            return of(
                                successDeleteFamilies({ payload: deleteFamilyIds.map(id => ({ id })) })
                            );
                        })
                    );
                } else {
                    return of(
                        successDeleteFamilies({
                            payload: [{ id: payload.removeRight.deleteMyRight.id }]
                        })
                    );
                }
            }),
            catchError(() => {
                return of(errorDeleteFamilies());
            })
        );
    });

    public effectSubject: Subject<string | HttpErrorResponse>;
    constructor(
        private _familyService: FamilyService,
        private _actions$: Actions,
        private store: Store,
        private _snackBar: SnackbarComponent,
        private _translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService,
        private _cacheService: CacheService,
        private _assetService: AssetService,
    ) {
        this.effectSubject = new Subject<string>();
    }
}
