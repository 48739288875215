import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'extractPropertyPipe'
})
export class ExtractPropertyPipe implements PipeTransform {

    constructor(
      private _translate: TranslateService,
    ) { }

    // Get an unknown type value, check and return the type of the received value
    transform(arrayOfValues: Array<{[x: string]: any}>, property: string, translate = false): Array<string> {
      const values = arrayOfValues?.map(value => translate ? this._translate.instant(value[property] ?? '') : value[property]);
      return values?.map(value => '\n' + (value ? value : '-')) ?? [];
    }
}
