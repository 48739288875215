import { Component, OnDestroy, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
 import { Zone } from 'src/app/models/zone';
import { Store } from '@ngrx/store';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ZoneEffects } from 'src/app/store/effects/zone.effects';
import { selectZoneById$ } from 'src/app/store/selectors/zone.selector';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-pop-in-zone-maintenance',
    templateUrl: './pop-in-zone-maintenance.component.html',
    styleUrls: ['./pop-in-zone-maintenance.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopInZoneMaintenanceComponent implements OnInit, OnDestroy {
    public zoneCreateForm: FormGroup<{
        name: FormControl<string>;
    }>;

    private _subscription: Subscription = new Subscription();

    constructor(
        @Inject(Zone) public zone: Zone,
        private _formBuilder: FormBuilder,
        private _zoneEffects: ZoneEffects,
        private store: Store,
        private _cd: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.zoneCreateForm = this._formBuilder.group({
            name: new FormControl(this.zone?.name, Validators.required),
        });
        this._subscription.add(
            this.zoneCreateForm.valueChanges.subscribe(
                ({ name }) => this.zone.name = name
            )
        );
    }

    public getParentZone(): Observable<Zone['name']> {
        return this.store.select(selectZoneById$(this.zone.parent)).pipe(
            map(zone => zone?.name),
            tap(() => this._cd.markForCheck()),
        );
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
}
