import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, map, filter, switchMap, tap, take } from 'rxjs/operators';
import { ParentFamilyService } from 'src/app/services/parent-family.service';
import {
    createParentFamily,
    deleteParentFamily,
    errorCreateParentFamilies,
    errorDeleteParentFamilies,
    errorFetchAllParentFamilies,
    errorUpdateParentFamilies,
    fetchAllParentFamilies,
    mappingAllParentFamiliesSites,
    mappingSomeParentFamiliesSites,
    successCreateParentFamilies,
    successDeleteParentFamilies,
    successFetchAllParentFamilies,
    successUpdateParentFamilies,
    updateParentFamily
} from '../actions/parent-family.action';
import { Store } from '@ngrx/store';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { ParentFamily } from 'src/app/models/family';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { selectSite$, selectSiteState$ } from '../selectors/site.selectors';
import { selectFamiliesByParentFamilyIds$ } from '../selectors/family.selectors';
import { successUpdateFamilies } from '../actions/family.action';
import { addParentFamilyProfile } from '../actions/profile.action';
import { CacheService } from 'src/app/services/cache.service';
import { RoleLevelEnum } from 'src/app/models/user-right';
import { deleteParentFromUserFilter } from '../actions/filter-context.action';
import { contextIsValid$, selectUserSelectedContext$ } from '../selectors/filter-context.selectors';
import { noop } from '../actions/_shared.action';
import * as featureActions from '../actions/user-right.action';
import { ObjectStore } from 'src/app/models/cache';

@Injectable()
export class ParentFamilyEffects {

    fetchAllParentFamilies$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(fetchAllParentFamilies),
            switchMap(() => this._cacheService.retrieveCacheStatus$()),
            map(status => status.parentFamilies?.lastRefresh),
            switchMap(lastRefresh => this._parentFamilyService.getParentFamilies(lastRefresh)),
            switchMap(parentFamilies => combineLatest([
                this._cacheService.deleteData$(ObjectStore.ParentFamilies, ...(parentFamilies.deletedData ?? []).map(({ id }) => id)),
                this._cacheService.updateData$(ObjectStore.ParentFamilies, ...(parentFamilies.updatedData ?? [])),
                this._cacheService.createData$(ObjectStore.ParentFamilies, ...(parentFamilies.createdData?? [])),
            ])),
            switchMap(() => this._cacheService.getData$(ObjectStore.ParentFamilies)),
            map((parentFamilies) => successFetchAllParentFamilies({ parentFamilies })),
            catchError(() => of(errorFetchAllParentFamilies())),
        );
    });

    public successFetchAllParentFamilies$ = createEffect(() => { return this._actions$.pipe(
        ofType(successFetchAllParentFamilies),
        switchMap((action) => this.store.select(selectSiteState$).pipe(
            filter(state => state.loaded),
            take(1),
            map((state) => mappingAllParentFamiliesSites({
                parentFamilies: action.parentFamilies,
                sites: state.data
            }))
        ))
    ) });

    public createParentFamilies$ = createEffect(() => { return this._actions$.pipe(
        ofType(createParentFamily),
        switchMap((action) => this._parentFamilyService.createParentFamily(action.parentFamily).pipe(
            map((new_parentFamily: ParentFamily | null) => {
                new_parentFamily.authorize = true;
                const new_parent_family_format_profile = {
                    id: new_parentFamily.id,
                    level: RoleLevelEnum.manager,
                    parent: '',
                };
                this.store.dispatch(addParentFamilyProfile({ profileParentFamily: new_parent_family_format_profile }));
                const text = this._translate.instant('BANNER_SUCCESS_CREATE', { item: action.parentFamily.name });
                this._snackBar.open(text, 'green-snackbar', 5000);
                this.effectSubject.next('success');
                return successCreateParentFamilies({ family: new_parentFamily });
            }),
            catchError(
                (error) => {
                    this._errorTranslationService.handleError(error, 'BANNER_FAIL_INTERNAL_CREATE');
                    this.effectSubject.next('error');
                    return of(errorCreateParentFamilies());

                }
            ),
        ))
    ) });

    public successCreateParentFamilies$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(successCreateParentFamilies),
            switchMap(({ family }) => combineLatest([
                this.store.select(contextIsValid$),
                this.store.select(selectUserSelectedContext$),
                this.store.select(selectSite$).pipe(
                    map(sites => sites.find(({ id }) => id === family.siteId)),
                ),
            ]).pipe(
                take(1),
                filter(([ valid ]) => valid),
                map(([, context, site]) => {
                    // If parent families or families are set, do nothing, but display message
                    let text = this._translate.instant('BANNER_SUCCESS_CREATE', { item: family.name });
                    if (context?.parentFamilies?.length || context?.families?.length) {
                        text += `\n${this._translate.instant('CONTEXT_FILTERED_BY__UPDATE_FOR_VALUE', {
                            type: this._translate.instant('PARENT_FAMILY')?.toLowerCase(),
                        })}`;
                        this._snackBar.open(text, 'green-snackbar', 5000);
                        return noop();
                    }
                    this._snackBar.open(text, 'green-snackbar', 5000);
                    // User is below asset limit, or has not filtered by family => add family
                    return mappingSomeParentFamiliesSites({ parentFamilies: [family], sites: [site] });
                }),
            )),
        );
    });

    public updateProfileOnCreation$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(successCreateParentFamilies),
            switchMap(({ family }) => combineLatest([
                this.store.select(contextIsValid$),
                this.store.select(selectUserSelectedContext$),
            ]).pipe(
                take(1),
                filter(([ valid ]) => valid),
                map(([, context]) => {
                    if (!context?.families?.length) {
                        return addParentFamilyProfile({ profileParentFamily: {
                            id: family.id,
                            level: 'manager',
                            parent: family.siteId,
                        } });
                    }
                }),
            )),
        );
    });

    public updateParentFamilies$ = createEffect(() => { return this._actions$.pipe(
        ofType(updateParentFamily),
        switchMap((action) => this._parentFamilyService.updateParentFamily(action.parentFamily, action.parentFamily.id).pipe(
            tap(parentFamilyUpdated => {
                this.effectSubject.next('success');
                const text: string = this._translate.instant('BANNER_SUCCESS_EDIT', { value: parentFamilyUpdated?.name });
                this._snackBar.open(text, 'green-snackbar', 5000);
            }),
            map((parentFamilyUpdated) => successUpdateParentFamilies({
                payload: [parentFamilyUpdated]
            })),
            catchError((error) => {
                this._errorTranslationService.handleError(error, 'BANNER_FAIL_EDIT');
                this.effectSubject.next('error');
                return of(errorUpdateParentFamilies());
            }),
        ))
    ) });

    public successUpdateParentFamily$ = createEffect(() => { return this._actions$.pipe(
        ofType(successUpdateParentFamilies),
        switchMap((action) => this.store.select(selectSiteState$).pipe(
            filter(state => state.loaded),
            take(1),
            tap((state) => this.store.dispatch(mappingSomeParentFamiliesSites({
                parentFamilies: action.payload,
                sites: state.data
            }))),
            // update children
            switchMap(() => this.store.select(selectFamiliesByParentFamilyIds$(action.payload.map(parent => parent.id))).pipe(take(1))),
            map((families) => successUpdateFamilies({ payload: families }))
        ))
    ) });

    public deleteParentFamilies$ = createEffect(() => { return this._actions$.pipe(
        ofType(deleteParentFamily),
        switchMap((action) => this._parentFamilyService.deleteParentFamily(action.payload).pipe(
            tap(() => this.effectSubject.next('success')),
            map(() => {
                this.store.dispatch(deleteParentFromUserFilter({ parentFamilyId: action.payload}));
                return successDeleteParentFamilies({ payload: [{ id: action.payload }] });
            }),
            catchError(error => {
                this.effectSubject.next(error);
                return of(errorDeleteParentFamilies());
            })
        )),
    ) });

    public removeParentFamilyBasedOnRemoveRights$ = createEffect(() => {
        return this._actions$.pipe(
            ofType<featureActions.SuccessDeleteMyRights>(featureActions.ActionTypes.SUCCESS_DELETE_MY_RIGHT),
            filter(({ payload }) => payload.removeRight.isParentFamily),
            map(({ payload }) => successDeleteParentFamilies({
                payload: [{ id: payload.removeRight.deleteMyRight.id }]
            })),
            catchError(() => of(errorDeleteParentFamilies())),
        );
    });

    public effectSubject: Subject<string | HttpErrorResponse>;
    constructor(
        private _parentFamilyService: ParentFamilyService,
        private _actions$: Actions,
        private store: Store,
        private _snackBar: SnackbarComponent,
        private _translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService,
        private _cacheService: CacheService,
    ) {
        this.effectSubject = new Subject<string>();
    }
}
