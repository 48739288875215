<!-- Overlay if the context is not valid -->
<div *ngIf="(contextIsValid$ | async) === false"
     class="overlay">
</div>

<div #kpiPage class="way-component--filterable-container">

    <!-- Filter -->
    <div #filterPanel class="way-filter-container">
        <app-kpi-filter (showOrHideFilter)="showOrHideFilter($event)"></app-kpi-filter>
    </div>


    <div *ngIf="loading$ | async" class="overlay">
        <mat-progress-spinner class="spinner" mode="indeterminate" value="50">
        </mat-progress-spinner>
    </div>

    <div class="way-table--main-container">

        <mat-tab-group #matTabGroup>

            <mat-tab label="{{ 'CYCLE_TIME_MONITORING' | translate }}">
                <app-kpi-cycle-time-monitoring [loaded]="loading$ | async" [dataKPIcycle]="kpiFamily$ | async"
                    [ngClass]="isshowOrHideFilter ?'scrollable-tab-filter-open' : 'scrollable-tab'"
                    [ngStyle]="{height: tabContentHeight}">
                </app-kpi-cycle-time-monitoring>
            </mat-tab>

            <mat-tab label="{{ 'LOOP_MONITORING' | translate }}">
                <app-kpi-loop-time-monitoring [loaded]="loading$ | async" [dataKPIloop]="kpiFamily$ | async"
                    [ngClass]="isshowOrHideFilter ?'scrollable-tab-filter-open' : 'scrollable-tab'"
                    [ngStyle]="{height: tabContentHeight}">
                </app-kpi-loop-time-monitoring>
            </mat-tab>

            <mat-tab label="{{ 'STATIC_TIME_MONITORING' | translate }}">
                <app-kpi-static-time-monitoring [loaded]="loading$ | async" [dataKPIstatic]="kpiFamily$ | async"
                    [ngClass]="isshowOrHideFilter ?'scrollable-tab-filter-open' : 'scrollable-tab'"
                    [ngStyle]="{height: tabContentHeight}">
                </app-kpi-static-time-monitoring>
            </mat-tab>

            <mat-tab label="{{ 'IN_OUT_ZONE' | translate }}">
                <app-kpi-in-out-zone-monitoring [loaded]="loading$ | async" [dataKPIinout]="kpiFamily$ | async"
                    [ngClass]="isshowOrHideFilter ?'scrollable-tab-filter-open' : 'scrollable-tab'"
                    [ngStyle]="{height: tabContentHeight}">
                </app-kpi-in-out-zone-monitoring>
            </mat-tab>

            <mat-tab label="{{ 'TIME_IN_ZONE_MONITORING' | translate }}">
                <app-kpi-time-in-zone-monitoring [loaded]="loading$ | async" [dataKPItimeinzone]="kpiTimeInZone$ | async"
                    [ngClass]="isshowOrHideFilter ?'scrollable-tab-filter-open' : 'scrollable-tab'"
                    [ngStyle]="{height: tabContentHeight}">
                </app-kpi-time-in-zone-monitoring>
            </mat-tab>

        </mat-tab-group>

    </div>
</div>
