// setting File Chunk size for 5 MB
export const CHUNK_SIZE: number = 5 * 1024 * 1024

export interface FileDescriptor {
    fileId: string;
    fileKey: string;
    parts: number;
}

export interface ProtectedSignURLResponse {
    signedUrl: string;
    partNumber: number;
}

export interface UploadPart {
    ETag: string;
    PartNumber: number;
  }
