import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { Group } from 'src/app/models/user-right';
import { UserRightEffects } from 'src/app/store/effects/user-right.effects';
import { selectGroupsOfUser$, selectRequestGroups$ } from 'src/app/store/selectors/user-right.selectors';
import * as userRightActions from 'src/app/store/actions/user-right.action';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogValidationComponent } from 'src/app/components/shared/dialog/dialog-validation/dialog-validation.component';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DialogRequestGroupComponent } from '../../dialog/dialog-request-group/dialog-request-group.component';
import { State } from 'src/app/store/reducers/user-right.reducer';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

export enum Actions {
    request,
    assign,
    none,
}

@Component({
  selector: 'app-user-groups-list',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserGroupsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() user!: User | (Partial<User> & Required<Pick<User, 'id'>>);
  @Input() qtyGroupsToDisplay = 5;
  private _disabled = false;
  @Input()
  get disabled() { return this._disabled; }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  @Input() public action: Actions = Actions.none;

  public groupsLoading = false;
  public groups$: Observable<State['data']['userGroups']>;
  public allGroups$: Observable<Group[]>;
  public groupInputForm = new FormControl<string>('');
  public readonly ACTIONS = Actions;

  private _subscription = new Subscription();

  constructor(
    private store: Store,
    private _userRightEffects: UserRightEffects,
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this._initializeGroups();
  }

  ngOnChanges({ user, qtyGroupsToDisplay, disabled, action }: SimpleChanges): void {
    if (user) {
      if (user.currentValue && user.currentValue !== user.previousValue) {
        this._fetchGroups(user.currentValue);
      }
    }
    if (qtyGroupsToDisplay) {
      if (qtyGroupsToDisplay.currentValue !== qtyGroupsToDisplay.previousValue) {
        this._cd.markForCheck();
      }
    }
    if (disabled) {
      if (disabled.currentValue !== disabled.previousValue) {
        this.groupInputForm[disabled.currentValue ? 'disable' : 'enable']();
        this._cd.markForCheck();
      }
    }
    if (action) {
        if (action.currentValue !== action.previousValue) {
            this._cd.markForCheck();
        }
    }
  }

  /**
   * Fetch the groups of the user
   * @param user The selected user
   */
  private _fetchGroups({ id }: User) {
    this.groupsLoading = true;
    this.store.dispatch(new userRightActions.FetchGroupsOfUser({ userId: id }));
    this._cd.markForCheck();
  }

  /**
   * Fetch all the groups
   */
  private _initializeGroups(): void {
    // Select the groups of the user
    this.groups$ = this.store.select(selectGroupsOfUser$).pipe(
      map((groups) => groups.sort((a, b) => a.name.localeCompare(b.name))),
    );

    // Handle the loading state end
    this._subscription.add(
      this._userRightEffects.effectSubject.pipe(
        filter(({ action }) => [
          userRightActions.ActionTypes.FETCH_GROUPS_OF_USER,
          userRightActions.ActionTypes.ADD_USERS_TO_GROUP,
          userRightActions.ActionTypes.REMOVE_USERS_FROM_GROUP,
        ].includes(action as userRightActions.ActionTypes))
      ).subscribe(
        () => {
          this.groupsLoading = false;
          this._cd.markForCheck();
        }
      )
    );
    // Build the list of available groups to select
    if (!this.disabled) {
      this.allGroups$ = combineLatest([
        this.store.select(selectRequestGroups$),
        this.groups$,
        this.groupInputForm.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : ''),
        ),
      ]).pipe(
        map(([allGroups, userGroups, searchedGroup]) => allGroups.filter(
          (group) => !userGroups.find(
            ({ id }) => group.id === id
          )
        ).sort(
          (a, b) => a.name.localeCompare(b.name)
        ).filter(
          group => group.name.toLowerCase().includes((searchedGroup as string).toLowerCase())
        )),
      );
      this.store.dispatch(new userRightActions.FetchListOfAllGroups());
    } else {
      this.allGroups$ = of([]);
    }
  }

  /**
   * Clear the input form of the group chip list
   */
  public clearGroupInput(): void {
    this.groupInputForm.setValue('');
  }

    /**
     * Handle the removal of a group chip
     * @param group The group to remove
     */
    public onRemoveGroup(group: Group): void {
        if (group.requested) {
            this.confirmGroupCancelRequest(group);
        } else {
            const dialogRef = this._dialog.open<DialogValidationComponent>(DialogValidationComponent, {
                data: {
                    title: this._translate.instant('REMOVE_GROUP', { groupName: group.name }),
                    textContent: this._translate.instant('REMOVE_GROUP_OF_USER_CONFIRMATION', { groupName: group.name }),
                    validateText: this._translate.instant('DIALOG_VALIDATE_OPTION'),
                    dangerousAction: true,
                }
            });
            this._subscription.add(
                dialogRef.componentInstance.onValidateEvent.subscribe(
                    () => this._removeGroup(group, dialogRef)
                )
            );
        }
    }

  /**
   * Handle the removal of the group
   * @param group The group to remove
   * @param dialogRef The dialog to close
   */
  private _removeGroup(group: Group, dialogRef?: MatDialogRef<any>): void {
    this.store.dispatch(new userRightActions.RemoveUsersFromGroup({
      group: group,
      users: [this.user.id],
    }));
    this._subscription.add(
      this._userRightEffects.effectSubject.pipe(
        filter(result => result.action === userRightActions.ActionTypes.REMOVE_USERS_FROM_GROUP),
        take(1),
      ).subscribe(
        ({ result }) => {
          switch (result) {
            case 'success':
              dialogRef?.close();
              break;
            default:
            // TODO: Handle the default
          }
        }
      )
    );
  }

  /**
   * Add a group to the selected User
   * @param group The group to add
   */
  private _addGroup(group: Group): void {
    this.groupsLoading = true;
    this.store.dispatch(new userRightActions.AddUsersToGroup({
      group,
      users: [this.user.id],
    }));
  }

  /**
   * Handle the selection of a group in the autocomplete
   * @param event The selection event
   */
  public selectGroup(event: MatAutocompleteSelectedEvent): void {
    this.clearGroupInput();
    this._addGroup(event.option.value);
  }

  /**
   * Opens the dialog for raise the request
   */
  public raiseARequestForGroup(): void {
    this._dialog.open(DialogRequestGroupComponent, {
        minWidth: '250px'
    });
  }

    /**
     * Opens the dialog to confirm the cancel the group request and handle the answer
     */
    public confirmGroupCancelRequest(group: Group) {
        const dialogRef = this._dialog.open<DialogValidationComponent>(DialogValidationComponent, {
            data: {
                title: this._translate.instant('CANCEL_GROUP_REQUEST'),
                textContent: this._translate.instant('CANCEL_YOUR_REQUEST_FOR_GROUP', { groupName: group.name }),
                validateText: this._translate.instant('DIALOG_VALIDATE_OPTION'),
                cancelText: this._translate.instant('CANCEL'),
            },
        });
        this._subscription.add(
            dialogRef.componentInstance.onValidateEvent.subscribe(
                () => this._cancelMyRequest(dialogRef, group)
            )
        );
    }

    /**
     *  Dispatch the store and close the dialog box
     * @param dialogRef The dialog to close
    */
    private _cancelMyRequest(dialogRef: MatDialogRef<DialogValidationComponent>, group: Group) {
        this._userRightEffects.effectSubject.pipe(
            filter(({ action }) => [
                userRightActions.ActionTypes.SUCCESS_CANCEL_GROUP_REQUEST,
                userRightActions.ActionTypes.ERROR_CANCEL_GROUP_REQUEST,
            ].includes(action as userRightActions.ActionTypes)),
            take(1),
        ).subscribe(
            () => dialogRef.close()
        );
        this.store.dispatch(new userRightActions.CancelGroupRequest({ groupData: group }));
    }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

}
