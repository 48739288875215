<app-in-zone-assets [dataKPI]="dataKPItimeinzone"
                    (chartClicked)="onInZoneChartClick($event)"></app-in-zone-assets>

<ng-container *ngIf="(idAssetClicked | typeOf) === 'string'; else selectElementTemplate">
  <app-in-zone-cycles [dataKPI]="dataKPItimeinzone"
                      [selectedAssetId]="idAssetClicked"></app-in-zone-cycles>
</ng-container>

<ng-template #selectElementTemplate>
    <app-more-kpi></app-more-kpi>
</ng-template>
